<template>
	<div class="reservation" :class="{ 'is-maintenance' : maintenance_flag }">

    <template v-if="maintenance_flag === true">
			<div class="maintenance eng">
				<h2 class="maintenance_ttl">Under maintenance <br class="sp">now</h2>
				<p class="maintenance_txt">The service is temporarily suspended due to maintenance. <br>We apologize for the inconvenience, but please wait for a while.</p>
			</div>
    </template>

		<template v-if="maintenance_flag === false">
			<MainVisual />

			<section class="formContent topContent">
				<div v-show="topAnnotation != undefined && topAnnotation != 'undefined'">
					<p class="formContent_eme" v-for="annotation in topAnnotation" :key="annotation" v-html="annotation"></p>
				</div>
				<ul class="factoryContents">
					<li class="factoryContent" v-for="topInfo in topTxts" :key="topInfo.title">
						<p class="factoryContent_ttl">{{ topInfo.title }}</p>	
						<ul>
							<li class="factoryList" v-for="inseption in topInfo.inseption_info" :key="inseption.name">
								<p class="factoryList_ttl">{{ inseption.name }}</p>
								<div class="factoryList_cont">
									<p v-for="content in inseption.content" :key="content" v-html="content"></p>
								</div>
							</li>
						</ul>
						<ul class="factoryRice">
							<li class="factoryRice_li factoryRice_rice" v-for="rice in topInfo.rice" :key="rice" v-html="rice"></li>
							<li class="factoryRice_li factoryRice_rice riceRed" v-for="red in topInfo.red_rice" :key="red" v-html="red"></li>
						</ul>
					</li>
				</ul>
			</section>

			<section class="formContent">
				<h3 class="formContent_ttl">Reservation process for tours</h3>
				<EngStep class="oneStep" />
				<section class="dateSelect courseOne">
					<section class="formNum courseOne">
						<h4 class="formNum_ttl">1.Select date</h4>
						<p class="formNum_txt">
							Choose a date with available time slots.
						</p>
						<ul class="carefulRice" v-if="topCalendar.red_rice">
							<li class="carefulRice_li" v-for="cal in topCalendar.red_rice" :key="cal" v-html="cal"></li>
						</ul>
						<ul class="exFlex">
							<li class="exBox">
								<div class="exBox_color boxFree"></div>
								<p class="exBox_txt">Accepting reservations</p>
							</li>
							<li class="exBox">
								<div class="exBox_color boxMax"></div>
								<p class="exBox_txt">Fully booked</p>
							</li>
							<li class="exBox">
								<div class="exBox_color boxOut"></div>
								<p class="exBox_txt">Reservation period closed</p>
							</li>
							<li class="exBox">
								<div class="exBox_color boxVacation"></div>
								<p class="exBox_txt">Closed on specified day</p>
							</li>
						</ul>
						<div id="calendarBox">
							<div id="calendar"></div>
						</div>
					</section>
					<section class="formNum courseBefore" v-if="selectYear == ''">
						<h4 class="formNum_ttl">2.Choose course</h4>
						<p class="formNum_txt">
							Select date first
						</p>
					</section>
					<section class="formNum courseTwo">
						<h4 class="formNum_ttl">2.Choose course</h4>
						<p class="formNum_txt" v-show="mygflg == true">
							The remaining number of people is an estimate.<br>
							Please note that it may change depending on the number of applications of other customers.<br>
							If you have any questions, please contact us by phone or email.
						</p>
						<p class="formNum_txt" v-show="mygflg == false">
							The remaining number of people is an estimate.<br>
							Please note that it may change depending on the number of applications of other customers.<br>
							If you have any questions, please contact us by phone or email.
						</p>
						<div class="twoTtl">
							<p class="twoTtl_date">{{ selectDay }},<br class="sp"> {{ selectMonth }} {{ selectDate }}, {{ selectYear }}</p>
							<p class="twoTtl_one" @click="stepOne()">Change date and time</p>
						</div>
						<div class="scaduleBox">
							<div class="cellBox cellFirst">
								<div class="cellBox_one"></div>
								<div class="cellBox_two cellBox_free">Distillery tour [free]</div>
							</div>
							<div class="cellBox" v-for="dateItem in targetItem" :key="dateItem.start_time">
								<div class="cellBox_one">{{ retakeDate(dateItem.start_time) }}</div>
								<div class="cellBox_two cellBox_infree">
									<div v-if="dateItem.rsv_course.length && dateItem.web_disp_flg == 1">
										<div v-for="courseItem in dateItem.rsv_course" :key="courseItem.rsv_course_id">
											<button type="button" class="selBtn freeBtn" :class="{ selected: selectedId + selectedTime === courseItem.rsv_course_id + dateItem.start_time}" v-if="courseItem.rsv_course_type_name.indexOf('有料') == -1 && courseItem.rsv_remaining_num > 0" :name="courseItem.rsv_course_id + dateItem.start_time" @click="getTxt(courseItem.rsv_course_id, dateItem.start_time)">
												{{ trim(courseItem.rsv_course_name) }}<br>
												{{ courseItem.rsv_remaining_num }} people left
											</button>
											<button type="button" class="maxBtn" v-if="courseItem.rsv_course_type_name.indexOf('有料') == -1 && courseItem.rsv_remaining_num <= 0">
												{{ trim(courseItem.rsv_course_name) }}<br>
												Full
											</button>
										</div>
									</div>
									<div v-else></div>
								</div>
							</div>
						</div>
						<p class="calendarCareful">For the last tour, the time after the tour is shorter due to the closing time.</p>
					</section>
				</section>
			</section>
			<Tel />
		</template>
	</div>
</template>

<script>
import $ from 'jquery';
import MainVisual from './EngMainVisual'
import EngStep from './EngStep'
import Tel from './EngTel'
import moment from 'moment'
import 'jquery-ui'
import axios from 'axios'
// require('../../node_modules/jquery-ui/ui/i18n/datepicker-ja.js')
require('../../node_modules/jquery-ui/ui/i18n/datepicker-en-AU.js')
require('@/assets/css/jquery-ui.css')
require('@/assets/css/calendar.scss')

export default {
	name: 'RservationTop',
	components: {
		MainVisual,
		EngStep,
		Tel
	},
	data () {
		return {
			windowSize: 0,
			url: "/api/reserveSlot/list",
			plantId: 0,
			mygflg: false,
			plantName: null,
			topTxts: [],
			topAnnotation: [],
			topCalendar: [],
			maintenance_flag: false,
			// ツイッター系
			pinned: [],
			pinnedDetail: [],
			tweets: [],
			// ツイッター系終わり
			dateParam: undefined,
			changeParam: undefined,
			thisDate: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM"),
			resData: '',
			date: new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }),
			year: new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getFullYear(),
			month: new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMonth(),
			lastDayOfFourthMonth: new Date(new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getFullYear(), new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMonth() + 2, 0),
			numberOfMonths: '',
			availableDates: '',
			availableDatesList: [],
			restDateList: [],
			maxDateList: [],
			$calendar: $("#calendar"),
			prevBtn: document.querySelector(".ui-datepicker-prev"),
			nextBtn: document.querySelector(".ui-datepicker-next"),
			selectedDate: '',
			selectYear: '',
			selectMonth: '',
			selectDate: '',
			selectDay: '',
			nowHours: new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getHours(),
			nowMinutes: new Date(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMinutes(),
			dayOfMonthStr: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			dayOfWeekStr: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
			targetDate: '',
			targetItem: '',
			modalItem: [],
			selectId: '',
			selectTime: '',
			selectEvent: null,
			selectedId: null,
			selectedTime: null,
			scrollPos: '',
			selectAvailable: null,
			winter: null,
			prefData: null,
			courseInfo: null,
			selectCourseInfo: null,
			comeWay: null,
			distance: 0,
			scrollStop: $('.twoTtl'),
			maxTxt: 0,
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'Distilleries | NIKKA WHISKY - English'
			}
		},
		meta: [
      { charset: 'utf-8' },
      { name: 'keywords', content: 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official'},
      { name: 'description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.nikka.com/eng/distilleries/miyagikyo/' },
      { property: 'og:title', content: 'Miyagikyo Distillery | Distilleries | NIKKA WHISKY - English' },
      { property: 'og:description', content: 'Built in 1969 as the second distillery of Nikka Whisky to make whisky different from Yoichi.' },
      { property: 'og:image', content: 'https://www.nikka.com/common/img/og.png' },
      { property: 'og:site_name', content: 'NIKKA WHISKY - English' },
      { property: 'og:locale', content: 'en_US' },
    ],
		link: [
      { rel: 'stylesheet', href: '/eng/css/reset.css' },
      { rel: 'stylesheet', href: '/eng/css/eng_common.css' },
      { rel: 'stylesheet', href: '/eng/css/layout.css' },
      // { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css' },
      { rel: 'stylesheet', href: '/eng/css/meanmenu.css' } 
    ],
		script: [
			{ type: 'text/javascript', src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/jquery.meanmenu.min.js', defer: true },
      { type: 'text/javascript', src: '/eng/js/eng_common.js', defer: true },
      { type: 'text/javascript', src: 'https://www.nikka.com/web-service/common/sc_parts/s_code.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_gnavi.js', defer: true },
			// { type: 'text/javascript', src: '/eng/js/local_footer.js', defer: true }
		]
	},
	mounted() {
		// 一番上まで移動
		$("body, html").scrollTop(0);
		
		// 余市か宮城峡か判定
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.plantId = 1000;
			this.plantName = 'Miyagikyo Distillery';
			this.mygflg = true;
			$(".reservation").addClass("miyagi");
      $(".reservation").addClass("eng");
		}else {
			this.plantId = 1001;
			this.plantName = 'Yoichi Distillery';
			this.mygflg = false;
			document.querySelector("meta[name='keywords']").setAttribute('content', 'nikka,whisky,yoichi,miyagikyo,single malt,pure malt,blended malt,taketsuru,masataka,rita,distillery,coffey,japanese,from the barrel,gin,vodka,official');
			document.querySelector("meta[name='description']").setAttribute('content', 'Built in 1934, Yoichi was the ideal place for Masataka Taketsuru’s whisky-making.');
			document.querySelector("meta[property='og:url']").setAttribute('content', 'https://www.nikka.com/eng/distilleries/yoichi/');
			document.querySelector("meta[property='og:title']").setAttribute('content', 'Yoichi Distillery | Distilleries | NIKKA WHISKY - English');
			document.querySelector("meta[property='og:site_name']").setAttribute('content', 'NIKKA WHISKY - English');
			$(".reservation").addClass("yoichi");
      $(".reservation").addClass("eng");
		}

		// 画面幅取得
		this.windowSize = $(window).width();


		localStorage.setItem('plantId', this.plantId);
		this.$emit('updateHead');

		axios.get('/files/' + this.plantId + '_top_eng.json')
		.then(res => {
			this.topAnnotation = res.data.data.annotation;
			this.topTxts = res.data.data.course_info;
			this.topCalendar = res.data.data.calendar_top[0];
		});

		// メンテナンス時の表示切替用フラグをjsonから取得
    axios.get("/files/maintenance.json").then((res) => {
      this.maintenance_flag = res.data.data.maintenance_flag;
		});
		
		// dateパラメーラがあるかどうか入れてみる
		this.dateParam = this.$route.query.date;
		this.changeParam = this.$route.query.change;

		this.resize();
		this.createCalendar();

		// リサイズの処理
		window.addEventListener('resize', this.resize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize);
	},
	methods: {
		resize() {
			var Pixcel =  $("#app").width();
			if(Pixcel <= 768) {
				// スマホ
				this.numberOfMonths = 1;
			}else {
				// PC
				this.numberOfMonths = 2;
			}
		},

		update(data) {
			for( var i = 0; i < data.length; i++ ) {
				console.log(data[i].user.name + ' : ' + data[i].text);
			}
		},
		
		// カレンダーの追加
		createCalendar() {
			axios({
				method: "post",
				url: this.url,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					plant_id: this.plantId,
					display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM"),
					language: 1
				}
			}).then(res => {
				this.resData = res;
				this.availableDates = res.data.data.date_info;
				this.availableDates.forEach(item => {
					let flg = 0;
					let max = 0;
					for(let i = 0; i < item.rsv_slot.length; i++) {
						if(item.rsv_slot[i].web_disp_flg == 1 && item.rsv_slot[i].rsv_course.length != 0) {
							flg++;
						}
						for(let m = 0; m < item.rsv_slot[i].rsv_course.length; m++) {
							if(item.rsv_slot[i].rsv_course[m].rsv_remaining_num > 0) {
								max++;
							}
						}
					}
					
					if(item.rest_flg == 1 && flg != 0 && max != 0) {
						this.availableDatesList.push(item);
					}else if(item.rest_flg == 1 && flg != 0 && max == 0) {
						this.maxDateList.push(item);
					}else if(item.rest_flg == 2) {
						this.restDateList.push(item);
					}
				});
			}).then(() => {
				var that = this;
				function available(date) {
					var daysFromCalendar = moment(date).format("YYYY-MM-DD");
					// JSON 内の営業日とカレンダー機能内の日を比較する
					var isAvailable = function (item) {
						return item.date == daysFromCalendar;
					}
					// 祝日かどうかを確認する
					var holiday = that.availableDatesList.filter(function (item) { return item.holiday_flg == 2 && isAvailable(item) })
					// 祝日の営業日なら holiday クラスを付ける 
					if (holiday && holiday.length) return [true, "holiday", "Available"];

					// 予約一杯の日を取得する
					var maxday = that.maxDateList.filter(function (item) {return isAvailable(item)})
					if(maxday && maxday.length) return [false, "maxday", "unAvailable"]

					// 休館日を取得
					var restday = that.restDateList.filter(function (item) {return isAvailable(item)})
					if(restday && restday.length) return [false, "restday", "unAvailable"]
					
					return that.availableDatesList.filter(function (item) { return isAvailable(item) }).length
						// 営業日
						? [true, "", "Available"]
						// 休館日
						: [false, "", "unAvailable"];
				}

				// 時間によって日付選択ができる範囲を変更（12時以降なら次の日も選択できないようにする）
				if(this.nowHours < 12) {
					// カレンダー機能
					$("#calendar").datepicker({
						// 一番左の日を 月曜日にする
						firstDay: 0,
						// 画面サイズによって表示する月数を指定する
						numberOfMonths: this.numberOfMonths,
						dateFormat: 'yy-mm-dd',
						// スマホでも最大 1ヵ月先までにしか行けないようにする
						minDate: '+1d',
						maxDate: this.lastDayOfFourthMonth,
						// 上記の available 変数を使って カレンダー作成する前に 表示するべき日を指定する
						beforeShowDay: available,
						// 日付が選択されたら選択された日を 送信する (別ウインドウを開く)
						onSelect: function(date) {
							if(localStorage.getItem('selectedEvent') != null) {
								localStorage.removeItem('selectedEvent');
								// イベント情報の非表示
								$('#selectedEvent').hide();
							}
							that.stepTwo(date);
						}
					}).datepicker("setDate", new Date());
				}else {
					// カレンダー機能
					$("#calendar").datepicker({
						// 一番左の日を 月曜日にする
						firstDay: 0,
						// 画面サイズによって表示する月数を指定する
						numberOfMonths: this.numberOfMonths,
						dateFormat: 'yy-mm-dd',
						// スマホでも最大 1ヵ月先までにしか行けないようにする
						minDate: '+2d',
						maxDate: this.lastDayOfFourthMonth,
						// 上記の available 変数を使って カレンダー作成する前に 表示するべき日を指定する
						beforeShowDay: available,
						// 日付が選択されたら選択された日を 送信する (別ウインドウを開く)
						onSelect: function(date) {
							if(localStorage.getItem('selectedEvent') != null) {
								localStorage.removeItem('selectedEvent');
								// イベント情報の非表示
								$('#selectedEvent').hide();
							}
							that.stepTwo(date);
						}
					}).datepicker("setDate", new Date());
				}

				// ロード時に選択状態につくクラスがついている場合外してあげる
				$('td').removeClass('ui-datepicker-current-day');
				$('a').removeClass('ui-state-active');

				// 蒸留所TOPのカレンダーからこのページに来たらステップ2に行く
				if(this.dateParam != undefined && this.changeParam == undefined) {
					$("#calendar").datepicker("setDate", this.dateParam);
					this.stepTwo(this.dateParam);
				}else if(this.dateParam != undefined && this.changeParam != undefined) {
					$("#calendar").datepicker("setDate", this.dateParam);
					this.stepOne();
				}
			})
		},

		async stepTwo(date) {
			var dd = new Date(new Date(date).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			this.selectedDate = moment(dd).format("YYYY-MM-DD");
			this.selectYear = dd.getUTCFullYear();
			this.selectMonth = this.dayOfMonthStr[dd.getUTCMonth()];
			this.selectDate = dd.getUTCDate();
			this.selectDay = this.dayOfWeekStr[dd.getDay()];
			// 日付が一致するものをAPIから探す
			this.targetDate = this.availableDates.find((v) => v.date === date);

			// 15時半以降出ないようにする
			this.targetItem = this.targetDate.rsv_slot.filter((result) => {
				return Number(result["start_time"].replace(/^0+/, '')) < 1531;
			});

			if(localStorage.getItem('selectedEvent') != null) {
				console.log("if");
				this.selectedId = JSON.parse(localStorage.getItem('selectedEvent')).rsv_course_id;
				this.selectedTime = localStorage.getItem('selectedTime');
				this.selectTime = this.retakeDate(this.selectedTime);
				$('.selBtn').removeClass('selected');

				this.selectedDate = localStorage.getItem('selectedDate');
				for(var i = 0; i < this.targetDate.rsv_slot.length; i++) {
					if(this.targetDate.rsv_slot[i].start_time == this.selectedTime) {
						for(var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
							if(this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.selectedId) {
								this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j]
							}
						}
					}
				}

				// 住所まとめたもの
				this.prefData = this.resData.data.data.departure;
				// 余市も宮城峡も海外以外削除
				// API側の対応で必要なくなった
				// this.prefData = this.prefData.filter((pref) => {
				// 	return pref["overseas_flg"] == 1;
				// });

				// 移動手段をまとめたもの
				this.courseInfo = this.resData.data.data.course_info;
				this.comeWay = this.resData.data.data.come_way;

				// コースの設定の移動手段に絞る
				for(var c = 0; c < this.courseInfo.length; c++) {
					if(this.courseInfo[c].course_id == this.selectId) {
						if(this.courseInfo[c].condition_transportation != null) {
							this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(',');
							for(var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
								for(var m = 0; m < this.comeWay.length; m++) {
									if(this.comeWay[m].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
										this.comeWay.splice(m, 1);
									}
								}
							}
						}
					}
				}
			}

			// $('.courseTwo').show();
			await this.showTwo();
		},

		showTwo() {
			const timer = setInterval(() => {
				if(this.selectedDate != '' && this.selectYear != '' && this.selectMonth != '' && this.selectDate != '' && this.selectDay != '' && this.targetDate != '') {
					// イベント情報の表示
					$('.courseTwo').show();
					const position = $('.courseTwo').offset().top;
					if(this.numberOfMonths == 1) {
						// 日付部分までスクロールする
						$("html, body").animate({
							scrollTop: position - 220
						}, 500);
					}else {
						// 日付部分までスクロールする
						$("html, body").animate({
							scrollTop: position - 250
						}, 500);
					}

					clearInterval(timer);
				}
			}, 200);
		},

		stepOne() {
			const position = $('.courseOne').offset().top;
			const speed = 500;
			$("html, body").animate({
				scrollTop: position
			}, speed);
		},

		takeMovie(medias) {
			const selectMovie = medias.filter((media) => {
				return media["content_type"] == "video/mp4";
			});

			return selectMovie[0]["url"];
		},

		retakeText(text, url) {
			const br = text.replace("\n", "<br>");
			if(url != "") {
				return br.replace(url, "");
			}
			return br
		},

		tweetDate(date) {
			let plastic = new Date(new Date(date).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
			let year = plastic.getFullYear();
			let month = plastic.getMonth() + 1;
			let day = plastic.getDate();
			let stickDate = `${year}.${month}.${day}`;

			return stickDate;
		},

		retakeDate(date) {
			var slice = Number(date);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		// ボタンの文字の制限をかける２１文字以上だったら…を後ろに
		trim(txt) {
			let noTag = $('<div>').html(txt).text();
			// デバイスサイズによって限界文字サイズを変更
			if(this.windowSize > 768) {
				this.maxTxt = 30;
			}else {
				this.maxTxt = 15;
			}

			// 文字のトリミング
			var txtLength = noTag.length;
			var txtTrim = noTag.substr(0, (this.maxTxt));
			if(txtLength > this.maxTxt) {
				return txtTrim + '…';
			}else {
				return noTag;
			}
		},

		getTxt(getId, time) {
			this.selectId = getId;
			this.defaultTime = time;

			$(this).removeClass('selected');

			for(var i = 0; i < this.targetDate.rsv_slot.length; i++) {
				if(this.targetDate.rsv_slot[i].start_time == this.defaultTime) {
					for(var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
						if(this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.selectId) {
							this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j]
						}
					}
				}
			}

			// 住所まとめたもの
			this.prefData = this.resData.data.data.departure;
			// 海外除外処理を入れる
			// API側の対応で必要なくなった
			// this.prefData = this.prefData.filter((pref) => {
			// 	return pref["overseas_flg"] == 1;
			// });

			// 移動手段をまとめたもの
			this.courseInfo = this.resData.data.data.course_info;
			this.comeWay = this.resData.data.data.come_way;

			// コースの設定の移動手段に絞る
			for(var c = 0; c < this.courseInfo.length; c++) {
				if(this.courseInfo[c].course_id == this.selectId) {
					if(this.courseInfo[c].condition_transportation != null) {
						this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(',');
						for(var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
							for(var m = 0; m < this.comeWay.length; m++) {
								if(this.comeWay[m].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
									this.comeWay.splice(m, 1);
								}
							}
						}
					}
				}
			}
			// シャトルバス対応
			if(this.mygflg) {
				var flg = 0;
				var openDay = this.resData.data.data.plant_info.pickup_bus_operating_day;
				for(var s = 0; s < openDay.length; s++) {
					if(openDay[s] == this.targetDate.date) {
						flg++;
					}
				}
				// jsonデータ内に日付がなかったらシャトルバス除外する
				if(flg == 0) {
					this.comeWay = this.comeWay.filter((come) => {
						return !/Shuttle bus/.test(come["come_way_name"]);
					});
				}
				// 移動手段絞る
				localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
			}else {
				// 余市はそのまま
				localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
			}

			this.selectCourseInfo = this.courseInfo.filter((array) => {
				return array["course_id"] == this.selectEvent["rsv_course_id"];
			});

			localStorage.setItem('courseInfo', JSON.stringify(this.courseInfo));
			localStorage.setItem('prefData', JSON.stringify(this.prefData));
			localStorage.setItem('visitNum', JSON.stringify(this.resData.data.data.vst_come_num));
			localStorage.setItem('selectedDate', this.selectedDate);
			localStorage.setItem('selectedTime', this.defaultTime);
			localStorage.setItem('selectedEvent', JSON.stringify(this.selectEvent));
			localStorage.setItem('selectCourseInfo', JSON.stringify(this.selectCourseInfo));

			if(this.mygflg == true) {
				this.$router.push({ path: '/eng/miyagikyo/reservation/select_event', query: { event_id: this.selectId, time: this.defaultTime } })
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation/select_event', query: { event_id: this.selectId, time: this.defaultTime } })
			}
			return false;
		},

		closeModal() {
			$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			$('.modal').fadeOut();
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.maintenance {
		padding-top: 80px;
		background: #251E1C;
		color: #FFF;
		&_ttl {
			font-size: 42px;
			font-weight: 400;
			text-align: center;
			margin-bottom: 48px;
			letter-spacing: 0.05em;
			line-height: 1.5 !important;
		}
		&_txt {
			font-size: 17px;
			line-height: 1.5 !important;
			margin-bottom: 24px;
			text-align: center;
			letter-spacing: 0.05em;
		}
		&_end {
			width: calc(100% - 20px);
			max-width: 484px;
			box-sizing: border-box;
			letter-spacing: 0.03em;
			text-align: center;
			color: #BD9C26;
			margin: 0 auto 24px;
			padding: 24px 0;
			border: 1px solid #BD9C26;
			border-radius: 4px;
			&_ttl {
				font-size: 17px;
			}
			&_date {
				font-size: 24px;
			}
		}
		&_last {
			font-size: 17px;
			line-height: 2 !important;
			text-align: center;
			margin-bottom: 0;
		}
	}

	// 768以下
	.picxel .maintenance {
		padding-top: 40px;
		&_ttl {
			width: calc(100% - 20px);
			font-size: 28px;
			margin: 0 auto 40px;
		}
		&_txt {
			width: calc(100% - 20px);
			margin: 0 auto 24px;
			font-size: 17px;
			text-align: left;
		}
		&_end {
			width: calc(100% - 20px);
			margin: 0 auto 24px;
			line-height: 1.8 !important;
			padding: 10px 40px;
		}
		&_last {
			width: calc(100% - 20px);
			margin: 0 auto;
			font-size: 17px;
			text-align: left;
		}
	}

	.maintenance.eng .goToLink {
		margin: 48px auto 80px;
	}

	.picxel .maintenance.eng .goToLink {
		margin: 32px auto 64px;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
<style lang="scss">
	.reservation.eng {
		background-color: #251E1C;
		color: #fff;
	}
	
	.infoLink {
		&_link {
			position: relative;
			display: block;
			width: 260px;
			margin: 30px auto 0;
			font-size: 20px;
			font-weight: bold;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	// 予約の流れコンテンツ
	.eng .formContent {
		width: 100%;
		max-width: 746px;
		margin: 0 auto;
		padding: 50px 0 60px;
		&_ttl {
			padding-bottom: 15px;
			font-size: 36px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 100;
		}
		&_careful {
			font-size: 16px;
			text-align: center;
			color: #FFF34F;
			margin-bottom: 20px;
		}
		&_eme {
			font-size: 18px;
			text-align: left;
			color: #FFF;
			margin-bottom: 48px;
		}
	}

	// 768以下
	.picxel .eng .formContent {
		width: calc(100% - 50px);
		padding-top: 41px;
		&_ttl {
			font-size: 26px;
			margin-bottom: 40px;
			padding-bottom: 6px;
		}
		&_careful {
			font-size: 16px;
			margin-bottom: 15px;
		}
		&_eme {
			font-size: 14px;
			text-align: left;
			margin-bottom: 40px;
		}
	}

	.topContent {
		padding: 73px 0 0 !important;
	}

	// 768以下
	.picxel .topContent {
		padding: 36px 0 0 !important;
	}

	// 一般見学
	.factoryContent {
		margin-bottom: 56px;
		&_ttl {
			font-size: 26px;
			font-weight: 400;
			letter-spacing: 0.08em;
			margin-bottom: 33px;
		}
	}
	
	// 768以下
	.picxel .eng .factoryContent {
		margin-bottom: 48px;
		&_ttl {
			font-size: 22px;
			margin-bottom: 25px;
		}
	}

	.factoryUl {
		margin-bottom: 17px;
	}

	// 768以下
	.picxel .eng .factoryUl {
		margin-bottom: 15px;
	}

	.win .mainFont.eng .factoryList {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: space-between;
		font-size: 17px;
		padding-bottom: 24px;
		margin-bottom: 24px;
		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 2px;
			background-size: 4px 2px;
			background-image: linear-gradient(to right, #595858, #595858 2px, transparent 2px, transparent 4px);
			background-repeat: repeat-x;
			opacity: 1;
		}
		&_ttl {
			position: relative;
			width: 220px;
			margin-right: 0;
			font-size: 17px;
			font-weight: 400;
			color: #898989;
			line-height: 2 !important;
		}
		&_cont {
			width: calc(100% - 220px);
			line-height: 2 !important;
			p {
				line-height: 2 !important;
			}
			&_btn {
				position: relative;
				display: inline-block;
				top: -1px;
				font-size: 13px;
				font-weight: bold;
				padding-right: 15px;
				margin-left: 5px;
				color: #FFF34F;
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #FFF34F;
					bottom: -2px;
					left: 0;
					transition: width .2s;
				}
				&::after {
					content: '';
					width: 6px;
					height: 6px;
					border: 0px;
					border-top: solid 2px #FFF34F;
					border-right: solid 2px #FFF34F;
					-ms-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -4px;
				}
				&:hover {
					&::before {
						width: 0;
					}
				}
			}
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .factoryList {
		font-size: 17px;
		margin-bottom: 10px;
		&_ttl {
			width: 100%;
			font-size: 16px;
		}
		&_cont {
			width: 100%;
			&_btn {
				font-size: 16px;
				&::after {
					width: 6px;
					height: 6px;
				}
			}
		}
	}


	.win .mainFont.eng .factoryRice {
		&_li {
			font-size: 14px;
			line-height: 2 !important;
			color: #FFF;
			span {
				color: #FFF !important;
			}
		}
		&_rice {
			position: relative;
			padding-left: 15px;
			&::before {
				content: '※';
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .factoryRice {
		&_li {
			font-size: 14px;
		}
		&_rice {
			padding-left: 15px;
		}
	}

	.riceRed {
		color: #FFF34F;
	}

	.win .mainFont .formNum {
		margin-top: 60px;
		padding-bottom: 80px;
		&_ttl {
			font-size: 26px;
			font-weight: 100;
			padding-bottom: 18.5px;
			margin-bottom: 23px;
			// border-bottom: 1px solid #000;
		}
		&_txt {
			font-size: 17px;
			line-height: 1.5 !important;
		}
	}

	.win .mainFont.eng .formNum{
		&_ttl {
			border-bottom: 1px solid #E8E8E8;
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .formNum {
		margin-top: 37px;
		padding-bottom: 60px;
		&_ttl {
			font-size: 22px;
			padding-bottom: 7px;
			margin-bottom: 20px;
		}
		&_txt {
			font-size: 17px;
		}
	}

	.win .mainFont.eng .carefulRice {
		margin-top: 16px;
		&_li {
			position: relative;
			padding-left: 15px;
			font-size: 14px;
			line-height: 1.5 !important;
			color: #FFF;
			&::before {
				content: "※";
				position: absolute;
				top: 0;
				left: 0;
			}
			&_txt {
				text-indent: 1em;
				&_span {
					display: block;
					text-indent: 3em;
				}
			}
			&_cont {
				span {
					display: block;
					line-height: 2 !important;
				}
				&_indent {
					text-indent: 2em !important;
				}
			}
		}
	}

	// 768以下
	.picxel.win .mainFont.eng .carefulRice {
		&_li {
			font-size: 14px;
			padding-left: 15px;
		}
	}

	.eng .exFlex {
		width: 100%;
		display: flex;
    flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		list-style: none;
		margin-top: 25px;
	}

	// 768以下
	.picxel .eng .exFlex {
		flex-wrap: wrap;
		width: 100%;
		margin-top: 20px;
	}

	.exBox {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		&_color {
			width: 40px;
			height: 40px;
			border: 1px solid #ddd;
		}
		.boxFree {
			background-color: #fff;
		}
		.boxMax {
			background-color: #FED6D6;
		}
		.boxOut {
			background-color: #C3C3C3;
		}
		.boxVacation {
			background-color: #D0DFEA;
		}
		&_txt {
			font-size: 18px;
			margin-left: 15px;
			line-height: 1;
		}
	}

	// 768以下
	.picxel .eng .exBox {
		width: 100%;
		margin-bottom: 15px;
		&:nth-child(4) {
			margin-bottom: 0;
		}
		&_color {
			width: 32px;
			height: 32px;
		}
		&_txt {
			font-size: 14px;
			margin-left: 10px;
		}
	}

	// カレンダー下のテキスト
	.win .mainFont.eng .calendarCareful {
		font-size: 17px;
		line-height: 2 !important;
		text-align: left;
		color: #FFF;
		margin-top: 20px;
	}

	.picxel.win .mainFont.eng .calendarCareful {
		font-size: 14px;
	}

	.courseTwo {
		display: none;
	}

	.eng .twoTtl {
		display: flex;
		align-items: center;
		margin-top: 65px;
		padding: 18px 25px 19px;
		&_date {
			font-size: 26px;
			font-weight: 600;
			letter-spacing: 0.075em;
			margin-right: 12px;
			line-height: 1;
		}
		&_one {
			position: relative;
			font-size: 18px;
			font-weight: 400;
			line-height: 1;
			margin-left: 12px;
			padding-right: 15px;
			cursor: pointer;
			&::after {
				content: '';
				width: 11px;
				height: 11px;
				border-top: solid 2px #F5E1D0;
				border-right: solid 2px #F5E1D0;
				position: absolute;
				right: 0;
				top: 8px;
				transform: rotate(45deg);
			}
		}
	}

	// 768以下
	.picxel .eng .twoTtl {
		display: block;
		margin-top: 40px;
		padding: 16px;
		&_date {
			font-size: 18px;
			margin-bottom: 12.5px;
		}
		&_one {
			font-size: 17px;
			width: 100%;
			margin-left: 0;
			padding-right: 15px;
			&::after {
				width: 11px;
				height: 11px;
			}
		}
	}

	.miyagi.eng .twoTtl, .yoichi.eng .twoTtl {
		justify-content: space-between;
		background-color: #898989;
		padding: 21px 48px 21px 24px;
		&_date {
			font-size: 28px;
			font-weight: 400;
			margin-right: 0;
		}
		&_one {
			font-size: 17px;
			color: #FFF;
			text-decoration: underline;
			margin-left: 0;
			padding-right: 0;
			padding-left: 15px;
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 3.25px 0 3.25px 7px;
				border-color: transparent transparent transparent #FFF;
			}
			&::after {
				display: none;
			}
		}
	}

	// スケジュールボックス
	.scaduleBox {
		margin-top: 40px;
		border-bottom: 1px solid #595858;
	}
	
	// 768以下
	.picxel .eng .scaduleBox {
		margin-top: 16px;
	}

	.eng .cellBox {
		width: 100%;
		display: flex;
		padding: 5px 0;
		border-bottom: 1px solid #595858;
		&_free {
			font-size: 17px;
			text-align: center;
		}
		&_one {
			font-size: 20px;
			width: calc(100% / 3);
			padding: 15px 0 10px 31px;
			margin-top: 5px;
			line-height: 1;
		}
		&_two {
			width: calc(100% / 3 * 2);
			margin-right: 30px;
			line-height: 2.2 !important;
		}
	}

	// 768以下
	.picxel .eng .cellBox {
		min-height: 81px;
		padding: 4px 0;
		&_free {
			font-size: 14px;
			margin-bottom: 20px;
		}
		&_paid {
			font-size: 11px;
		}
		&_one {
			font-size: 14px;
			margin-top: 12px;
			padding-left: 0;
		}
		&_two {
			line-height: 1.5 !important;
			margin-right: 5px;
		}
		&_three {
			line-height: 1.5 !important;
			margin-right: 5px;
		}
	}

	.picxel .eng .cellBox.cellFirst {
		min-height: 0px;
	}

	.miyagi.eng .cellBox, .yoichi.eng .cellBox {
		&_free {
			color: #fff;
		}
	}

	// ボタン
	.eng .noneBtn {
		display: block;
		width: 100%;
		margin: 5px 0;
		padding: 10px 0;
		font-size: 17px;
		outline: none;
		border-radius: 4px;
		box-sizing: border-box;
	}

	.miyagi .noneBtn {
		color: #E9E9E9;
		border: 3px solid #E9E9E9;
		background-color: #E9E9E9;
	}

	.yoichi .noneBtn {
		color: #E9E9E9;
		border: 3px solid #E9E9E9;
		background-color: #E9E9E9;
	}

	.miyagi.eng .noneBtn, .yoichi.eng .noneBtn {
		color: #FFF;
		border: none;
		background-color: #836c4a;
	}

	// 768以下
	.picxel .eng .noneBtn {
		margin: 4px 0;
		padding: 10px 0;
		font-size: 14px;
		border-radius: 4px;
	}

	// 満席ボタン
	.eng .maxBtn {
		display: block;
		width: 100%;
		margin: 5px 0;
		padding: 10px 0;
		font-size: 17px;
		outline: none;
		color: #C0C0C0;
		border-radius: 4px;
		box-sizing: border-box;
		opacity: .4;
	}

	// 768以下
	.picxel .eng .maxBtn {
		margin: 5px 0;
		padding: 10px 0;
		font-size: 14px;
		border-radius: 4px;
	}

	.miyagi.eng .maxBtn, .yoichi.eng .maxBtn {
		background-color: #91701e;
		border: none;
	}

	// 動的に追加された無料見学スケジュールボタン
	.eng .freeBtn {
		position: relative;
		display: block;
		width: 100%;
		margin: 5px 0;
		padding: 10px 52px;
		font-size: 17px;
		outline: none;
		color: #fff;
		border-radius: 4px;
		box-sizing: border-box;
		cursor: pointer;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 32px;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 10px;
			border-color: transparent transparent transparent #FFF;
		}
	}

	// 768以下
	.picxel .eng .freeBtn{
		margin: 5px 0;
		padding: 10px 0;
		font-size: 14px;
		border-radius: 4px;
	}

	.miyagi.eng .freeBtn, .yoichi.eng .freeBtn{
		background-color: #91701e;
		border: none;
	}

	.selectedList {
		list-style: none;
		display: flex;
		width: 480px;
		margin: 80px auto 0;
		font-size: 20px;
		letter-spacing: 0.15em;
		line-height: 2 !important;
		font-weight: bold;
		&_ttl {
			margin-right: 15px;
		}
		&_name {
			list-style: none;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0.15em;
			width: 480px;
			margin: 0 auto;
		}
	}

	// 768以下
	.picxel .eng .selectedList {
		display: block;
		width: 100%;
		font-size: 16px;
		margin-bottom: 22px;
		&_ttl {
			margin-right: 0;
		}
		&_name {
			width: 100%;
			font-size: 16px;
		}
	}

	.nextPage {
		display: block;
		position: relative;
		width: 320px;
		color: #fff;
		background-color: #AF9754;
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		border: none;
		outline: none;
		padding: 21px 0;
		margin: 40px auto 20px;
		cursor: pointer;
		&::after {
			content: '';
			width: 7px;
			height: 7px;
			border: 0px;
			border-top: solid 2px #fff;
			border-right: solid 2px #fff;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: rotate(45deg)translateY(-50%);
			margin-top: -2px;
		}
	}

	// 768以下
	.picxel .eng .nextPage {
		width: 100%;
		font-size: 16px;
		padding: 16px 0;
		margin: 20px auto 15px;
		&::after {
			width: 7px;
			height: 7px;
		}
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .pc {
		display: none;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
