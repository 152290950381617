<template>
	<div class="tel">
		<div v-show="mygflg == true">
			<p class="tel_ttl">ご予約・見学等に関する<br class="sp">お問合せ</p>
			<div class="telAndFax">
				<div class="backNone">
					<img src="../assets/img/telIcon.png" alt="電話アイコン" class="backNone_img">
					<a href="tel:0223952865" class="backNone_num">022-395-2865</a>
				</div>
				<div class="backNone">
					<img src="../assets/img/faxIcon.png" alt="FAXアイコン" class="backNone_img">
					<a href="tel:0223952305" class="backNone_num">022-395-2305</a>
				</div>
			</div>
			<div class="backRec">
				<p class="backRec_ttl">お電話受付時間<span>10:00～16:15</span><br class="sp"><span>（休業日を除く）</span></p>
			</div>
		</div>
		<div v-show="mygflg == false">
			<p class="tel_ttl">ご予約・見学等に関する<br class="sp">お問合せ</p>
			<div class="telAndFax">
				<div class="backNone">
					<img src="../assets/img/telIcon.png" alt="電話アイコン" class="backNone_img">
					<a href="tel:0135233131" class="backNone_num">0135-23-3131</a>
				</div>
				<div class="backNone">
					<img src="../assets/img/faxIcon.png" alt="FAXアイコン" class="backNone_img">
					<a href="tel:0135233137" class="backNone_num">0135-23-3137</a>
				</div>
			</div>
			<div class="backRec">
				<p class="backRec_ttl">お電話受付時間<span>9:00〜16:15</span><br class="sp"><span>（休業日を除く）</span></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tel',
	data () {
		return {
			mygflg: false
		}
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		var userAgent = window.navigator.userAgent.toLowerCase();
		if(userAgent.indexOf('safari') != -1 && userAgent.indexOf('chrome') == -1) {
			if(performance.navigation.type == 1) {
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			}
		}else {
			window.$(window).on('beforeunload', () => {
				// ブラウザ閉じたときにまたはリロードしたときに
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.tel {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-bottom: 100px;
    color: #000;
    &_ttl {
      font-size: 26px;
      font-weight: bold;
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid #E8E8E8;
    }
  }

  // 768以下
  .picxel .tel {
    width: calc(100% - 50px);
    padding-bottom: 96px;
    &_ttl {
      font-size: 22px;
      padding-bottom: 26px;
      margin-bottom: 24px;
    }
  }

  .telAndFax {
    display: flex;
    align-items: center;
  }

  // 768以下
  .picxel .telAndFax {
    display: block;
  }

  .backNone {
    display: flex;
    align-items: center;
    margin-right: 40px;
    &_img {
      width: 30px;
    }
    &_num {
      font-size: 36px;
      color: #000;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  // 768以下
  .picxel .backNone {
    margin: 0 0 13px 0;
    &_img {
      width: 30px;
      margin-right: 10px;
    }
    &_num {
      font-size: 30px;
    }
  }

  .backRec {
    &_ttl {
      font-size: 16px;
      margin-top: 18px;
    }
  }
  
  // 768以下
  .picxel .backRec {
    &_ttl {
      font-size: 16px;
      margin-top: 17px;
    }
  }

	.sp {
		display: none;
	}

	// 768以下
  .picxel .sp {
		display: block;
	}
</style>
