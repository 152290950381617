<template>
	<div class="eventMiyagi" :class="{ 'is-maintenance' : maintenance_flag }">

		<template v-if="maintenance_flag === true">
      <div class="maintenance">
        <h2 class="maintenance_ttl">ただいま<br class="sp" />メンテナンス中です</h2>
        <p class="maintenance_txt">メンテナンス中のため一時サービスを停止しております。<br />大変ご不便をおかけいたしますが、今しばらくお待ちください。</p>
      </div>
    </template>

		<template v-if="maintenance_flag === false">
			<MainVisual />
			<!-- <div class="noticeBox">
				<section class="noticeSection">
					<h3 class="noticeSection_ttl">お知らせ</h3>
					<section class="noticeContent">
						<h4 class="noticeContent_ttl">「柏工場」2021年地域ふれあい感謝デーの中止のお知らせ</h4>
						<p class="noticeContent_txt">
							このたび新型コロナウイルス感染症の影響を受け、本年5月23日（日）に開催を予定しておりました地域ふれあい感謝デーを中止とさせていただきます。楽しみにされていたお客様には誠に申し訳ございませんが、何卒ご理解を賜りますようお願い申し上げます。
						</p>
					</section>
				</section>
			</div> -->
			<div class="container">
				<!-- アンカーリンク start -->
				<div class="events_links eventsLinks" v-show="eventflg == true">
					<div class="eventsLinks_ttl mincho">
						各蒸溜所の<br class="u-sp">イベント・セミナー情報
					</div>
					<div class="eventsLinks_btns" v-show="yoichibefore == false">
						<div class="eventsLinksBtns_item _miyagikyo" @click="miyagikyoAnchorEvents()">
							<span class="eventsLinksBtns_txt mincho">
								宮城峡蒸溜所
							</span>
							<span class="eventsLinksBtns_arrow">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.334 4.874">
									<path d="M6979.936,2379.447l3.813,3.813-3.813,3.814" transform="translate(2387.428 -6979.582) rotate(90)"
										fill="none" stroke="#af9754" stroke-width="1" />
								</svg>
							</span>
						</div>
						<div class="eventsLinksBtns_item _yoichi" @click="yoichiAnchorEvents()">
							<span class="eventsLinksBtns_txt mincho">
								余市蒸溜所
							</span>
							<span class="eventsLinksBtns_arrow">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.334 4.874">
								<path d="M6979.936,2379.447l3.813,3.813-3.813,3.814" transform="translate(2387.428 -6979.582) rotate(90)" fill="none"
									stroke="#af9754" stroke-width="1" />
								</svg>
							</span>
						</div>
					</div>
					
					<div class="eventsLinks_btns" v-show="yoichibefore == true">
						<div class="eventsLinksBtns_item _yoichi" @click="yoichiAnchorEvents()">
							<span class="eventsLinksBtns_txt mincho">
								余市蒸溜所
							</span>
							<span class="eventsLinksBtns_arrow">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.334 4.874">
								<path d="M6979.936,2379.447l3.813,3.813-3.813,3.814" transform="translate(2387.428 -6979.582) rotate(90)" fill="none"
									stroke="#af9754" stroke-width="1" />
								</svg>
							</span>
						</div>
						<div class="eventsLinksBtns_item _miyagikyo" @click="miyagikyoAnchorEvents()">
							<span class="eventsLinksBtns_txt mincho">
								宮城峡蒸溜所
							</span>
							<span class="eventsLinksBtns_arrow">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.334 4.874">
									<path d="M6979.936,2379.447l3.813,3.813-3.813,3.814" transform="translate(2387.428 -6979.582) rotate(90)"
										fill="none" stroke="#af9754" stroke-width="1" />
								</svg>
							</span>
						</div>
					</div>
				</div>

				<!-- イベント一覧 start -->
				<div class="events_items eventsItems"  v-show="yoichibefore == false && loading == false">
					<div class="eventsItems_inner" id="miyagikyoEvents" v-show="eventflg == true || mygflg == true">
						<div class="eventsItems_top mincho">
							宮城峡蒸溜所
						</div>
						<!-- <p class="eventsItemsMain_pause">【只今セミナーは休止しております。再開に関しては、改めてご案内いたします。】</p> -->
						<ul>
							<li class="eventsItems_main _miyagikyo" v-for="miyagikyoPickup in miyagikyoMain" :key="miyagikyoPickup.course_id">
								<div class="eventsItemsMain_inner">
									<div class="eventsItemsMain_img" v-show="miyagikyoPickup.image">
										<img :src="`/storage/${miyagikyoPickup.image}`" alt="">
										<p class="eventsItemsMain_img-txt mincho">PICK UP</p>
									</div>
									<div class="eventsItemsMain_details">
										<div class="eventsItemsMain_details-openDate _miyagikyo">
											<span>開催日 : </span>
											<span v-html="miyagikyoPickup.course_date"></span>
										</div>
										<div class="eventsItemsMain_details-ttl" v-html="miyagikyoPickup.name"></div>
										<div class="eventsItemsMain_details-desc" v-show="miyagikyoPickup.overview != ''" v-html="miyagikyoPickup.overview"></div>
										<div class="eventsItemsMain_details-fee" v-show="miyagikyoPickup.fee != ''">
											<span>参加費 : </span>
											<span v-html="miyagikyoPickup.fee"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTime">
											<span>所要時間 : </span>
											<span v-html="miyagikyoPickup.total_time"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTimeText" v-show="miyagikyoPickup.timetable != ''">
											<span>内訳 : </span>
											<span v-html="miyagikyoPickup.timetable"></span>
										</div>
										<!-- <div class="prohibited" v-show="miyagikyoPickup.reminder_icon_flg === 1">
											<ul class="prohibited__list">
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
											</ul>
										</div> -->
									</div>
								</div>
								<div class="eventsItemsMain_outer">
									<div class="eventsItemsMain_details-tags">


										<p v-if="miyagikyoPickup.type == 3" class="eventsItemsMain_details-tag _pay">無料</p>
										<p v-else-if="miyagikyoPickup.type == 4" class="eventsItemsMain_details-tag _pay">有料</p>

										<p class="eventsItemsMain_details-tag _test" v-show="miyagikyoPickup.tag_1" v-html="miyagikyoPickup.tag_1"></p>
										<p class="eventsItemsMain_details-tag _visit" v-show="miyagikyoPickup.tag_2" v-html="miyagikyoPickup.tag_2"></p>
									</div>
									<div class="eventsItemsMain_details-link js-modal_openBtn" v-show="miyagikyoPickup.detail_disp_flg == true" @click="eventDetail(1000, miyagikyoPickup.course_id)">
										<span class="eventsItemsMain_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsMain_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646"><g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsMain_details-caution" v-show="miyagikyoPickup.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</li>
						</ul>
					
						<div class="eventsItems_sub _miyagikyo">
							<div class="eventsItemsSub_item" v-for="miyagikyoItem in miyagikyoCourses" :key="miyagikyoItem.course_id">
								<div class="eventsItemsSubItem_imgs">
									<div class="eventsItemsSubItem_img" v-show="miyagikyoItem.image">
										<img :src="`/storage/${miyagikyoItem.image}`" alt="">
										<p class="eventsItemsSubItem_img-txt mincho" v-show="miyagikyoItem.pickup_flg == 1">PICK UP</p>
									</div>
									<div class="eventsItemsSubItem_details-openDate _miyagikyo">
										<span>開催日 : </span>
										<span v-html="miyagikyoItem.course_date"></span>
									</div>
								</div>
								<div class="eventsItemsSubItem_details">
									<div class="eventsItemsSubItem_details-ttl" v-html="miyagikyoItem.name"></div>
									<div class="eventsItemsSubItem_details-desc" v-show="miyagikyoItem.overview != ''" v-html="miyagikyoItem.overview"></div>
									<div class="eventsItemsSubItem_details-fee" v-show="miyagikyoItem.fee != ''">
										<span>参加費 : </span>
										<span v-html="miyagikyoItem.fee"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTime">
										<span>所要時間 : </span>
										<span v-html="miyagikyoItem.total_time"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTimeText" v-show="miyagikyoItem.timetable != ''">
										<span>内訳 : </span>
										<span v-html="miyagikyoItem.timetable"></span>
									</div>

									<!-- <div class="prohibited" v-show="miyagikyoItem.reminder_icon_flg === 1">
										<ul class="prohibited__list">
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
										</ul>
									</div> -->

									<div class="eventsItemsSubItem_details-tags">
										
										<p v-if="miyagikyoItem.type == 3" class="eventsItemsSubItem_details-tag _pay">無料</p>
										<p v-else-if="miyagikyoItem.type == 4" class="eventsItemsSubItem_details-tag _pay">有料</p>

										<p class="eventsItemsSubItem_details-tag _test" v-show="miyagikyoItem.tag_1" v-html="miyagikyoItem.tag_1"></p>
										<p class="eventsItemsSubItem_details-tag _visit" v-show="miyagikyoItem.tag_2" v-html="miyagikyoItem.tag_2"></p>
									</div>
									<div class="eventsItemsSubItem_details-link js-modal_openBtn" v-show="miyagikyoItem.detail_disp_flg == true" @click="eventDetail(1000, miyagikyoItem.course_id)">
										<span class="eventsItemsSubItem_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsSubItem_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646">
												<g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsSubItem_details-caution" v-show="miyagikyoItem.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</div>
						</div>
					</div>

					<div class="eventsItems_inner" id="yoichiEvents" v-show="eventflg == true || yoichiflg == true">
						<div class="eventsItems_top mincho">
							余市蒸溜所
						</div>

						<ul>
							<li class="eventsItems_main _yoichi" v-for="yoichiPickup in yoichiMain" :key="yoichiPickup.course_id">
								<div class="eventsItemsMain_inner">
									<div class="eventsItemsMain_img" v-show="yoichiPickup.image">
										<img :src="`/storage/${yoichiPickup.image}`" alt="">
										<p class="eventsItemsMain_img-txt mincho">PICK UP</p>
									</div>
									<div class="eventsItemsMain_details">
										<div class="eventsItemsMain_details-openDate _yoichi">
											<span>開催日 : </span>
											<span v-html="yoichiPickup.course_date"></span>
										</div>
										<div class="eventsItemsMain_details-ttl" v-html="yoichiPickup.name"></div>
										<div class="eventsItemsMain_details-desc" v-show="yoichiPickup.overview != ''" v-html="yoichiPickup.overview"></div>
										<div class="eventsItemsMain_details-fee" v-show="yoichiPickup.fee != ''">
											<span>参加費 : </span>
											<span v-html="yoichiPickup.fee"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTime">
											<span>所要時間 : </span>
											<span v-html="yoichiPickup.total_time"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTimeText" v-show="yoichiPickup.timetable != ''">
											<span>内訳 : </span>
											<span v-html="yoichiPickup.timetable"></span>
										</div>
										<div class="prohibited" v-show="yoichiPickup.reminder_icon_flg === 1">
											<ul class="prohibited__list">
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="eventsItemsMain_outer">
									<div class="eventsItemsMain_details-tags">

										<p v-if="yoichiPickup.type == 8" class="eventsItemsMain_details-tag _pay">無料</p>
										<p v-else-if="yoichiPickup.type == 9" class="eventsItemsMain_details-tag _pay">有料</p>

										<p class="eventsItemsMain_details-tag _test" v-show="yoichiPickup.tag_1" v-html="yoichiPickup.tag_1"></p>
										<p class="eventsItemsMain_details-tag _visit" v-show="yoichiPickup.tag_2" v-html="yoichiPickup.tag_2"></p>
									</div>
									<div class="eventsItemsMain_details-link js-modal_openBtn" v-show="yoichiPickup.detail_disp_flg == true" @click="eventDetail(1001, yoichiPickup.course_id)">
										<span class="eventsItemsMain_details-link_txt">詳しく見る</span>
										<span class="eventsItemsMain_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646"><g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsMain_details-caution" v-show="yoichiPickup.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</li>
						</ul>
						
						<div class="eventsItems_sub _yoichi">
							<div class="eventsItemsSub_item" v-for="yoichiItem in yoichiCourses" :key="yoichiItem.course_id">
								<div class="eventsItemsSubItem_imgs">
									<div class="eventsItemsSubItem_img" v-show="yoichiItem.image">
										<img :src="`/storage/${yoichiItem.image}`" alt="">
										<p class="eventsItemsSubItem_img-txt" v-show="yoichiItem.pickup_flg == 1">PICK UP</p>
									</div>
									<div class="eventsItemsSubItem_details-openDate _yoichi">
										<span>開催日 : </span>
										<span v-html="yoichiItem.course_date"></span>
									</div>
								</div>
								<div class="eventsItemsSubItem_details">
									<div class="eventsItemsSubItem_details-ttl" v-html="yoichiItem.name"></div>
									<div class="eventsItemsSubItem_details-desc" v-show="yoichiItem.overview != ''" v-html="yoichiItem.overview"></div>
									<div class="eventsItemsSubItem_details-fee" v-show="yoichiItem.fee != ''">
										<span>参加費 : </span>
										<span v-html="yoichiItem.fee"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTime">
										<span>所要時間 : </span>
										<span v-html="yoichiItem.total_time"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTimeText" v-show="yoichiItem.timetable != ''">
										<span>内訳 : </span>
										<span v-html="yoichiItem.timetable"></span>
									</div>


									<div class="prohibited" v-show="yoichiItem.reminder_icon_flg === 1">
										<ul class="prohibited__list">
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
										</ul>
									</div>


									<div class="eventsItemsSubItem_details-tags">
										<p v-if="yoichiItem.type == 8" class="eventsItemsSubItem_details-tag _pay">無料</p>
										<p v-else-if="yoichiItem.type == 9" class="eventsItemsSubItem_details-tag _pay">有料</p>
										<p class="eventsItemsSubItem_details-tag _test" v-show="yoichiItem.tag_1" v-html="yoichiItem.tag_1"></p>
										<p class="eventsItemsSubItem_details-tag _visit" v-show="yoichiItem.tag_2" v-html="yoichiItem.tag_2"></p>
									</div>
									<div class="eventsItemsSubItem_details-link js-modal_openBtn" v-show="yoichiItem.detail_disp_flg == true" @click="eventDetail(1001, yoichiItem.course_id)">
										<span class="eventsItemsSubItem_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsSubItem_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646">
												<g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsSubItem_details-caution u-font_notoSans" v-show="yoichiItem.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="events_items eventsItems" v-show="yoichibefore == true && loading == false">

					<div class="eventsItems_inner" id="yoichiEvents" v-show="eventflg == true || yoichiflg == true">
						<div class="eventsItems_top mincho">
							余市蒸溜所
						</div>

						<ul>
							<li class="eventsItems_main _yoichi" v-for="yoichiPickup in yoichiMain" :key="yoichiPickup.course_id">
								<div class="eventsItemsMain_inner">
									<div class="eventsItemsMain_img" v-show="yoichiPickup.image">
										<img :src="`/storage/${yoichiPickup.image}`" alt="">
										<p class="eventsItemsMain_img-txt mincho">PICK UP</p>
									</div>
									<div class="eventsItemsMain_details">
										<div class="eventsItemsMain_details-openDate _yoichi">
											<span>開催日 : </span>
											<span v-html="yoichiPickup.course_date"></span>
										</div>
										<div class="eventsItemsMain_details-ttl" v-html="yoichiPickup.name"></div>
										<div class="eventsItemsMain_details-desc" v-show="yoichiPickup.overview != ''" v-html="yoichiPickup.overview"></div>
										<div class="eventsItemsMain_details-fee" v-show="yoichiPickup.fee != ''">
											<span>参加費 : </span>
											<span v-html="yoichiPickup.fee"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTime">
											<span>所要時間 : </span>
											<span v-html="yoichiPickup.total_time"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTimeText" v-show="yoichiPickup.timetable != ''">
											<span>内訳 : </span>
											<span v-html="yoichiPickup.timetable"></span>
										</div>
										<div class="prohibited" v-show="yoichiPickup.reminder_icon_flg === 1">
											<ul class="prohibited__list">
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
												<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="eventsItemsMain_outer">
									<div class="eventsItemsMain_details-tags">

										<p v-if="yoichiPickup.type == 8" class="eventsItemsMain_details-tag _pay">無料</p>
										<p v-else-if="yoichiPickup.type == 9" class="eventsItemsMain_details-tag _pay">有料</p>

										<p class="eventsItemsMain_details-tag _test" v-show="yoichiPickup.tag_1" v-html="yoichiPickup.tag_1"></p>
										<p class="eventsItemsMain_details-tag _visit" v-show="yoichiPickup.tag_2" v-html="yoichiPickup.tag_2"></p>
									</div>
									<div class="eventsItemsMain_details-link js-modal_openBtn" v-show="yoichiPickup.detail_disp_flg == true" @click="eventDetail(1001, yoichiPickup.course_id)">
										<span class="eventsItemsMain_details-link_txt">詳しく見る</span>
										<span class="eventsItemsMain_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646"><g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsMain_details-caution" v-show="yoichiPickup.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</li>
						</ul>
						
						<div class="eventsItems_sub _yoichi">
							<div class="eventsItemsSub_item" v-for="yoichiItem in yoichiCourses" :key="yoichiItem.course_id">
								<div class="eventsItemsSubItem_imgs">
									<div class="eventsItemsSubItem_img" v-show="yoichiItem.image">
										<img :src="`/storage/${yoichiItem.image}`" alt="">
										<p class="eventsItemsSubItem_img-txt" v-show="yoichiItem.pickup_flg == 1">PICK UP</p>
									</div>
									<div class="eventsItemsSubItem_details-openDate _yoichi">
										<span>開催日 : </span>
										<span v-html="yoichiItem.course_date"></span>
									</div>
								</div>
								<div class="eventsItemsSubItem_details">
									<div class="eventsItemsSubItem_details-ttl" v-html="yoichiItem.name"></div>
									<div class="eventsItemsSubItem_details-desc" v-show="yoichiItem.overview != ''" v-html="yoichiItem.overview"></div>
									<div class="eventsItemsSubItem_details-fee" v-show="yoichiItem.fee != ''">
										<span>参加費 : </span>
										<span v-html="yoichiItem.fee"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTime">
										<span>所要時間 : </span>
										<span v-html="yoichiItem.total_time"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTimeText" v-show="yoichiItem.timetable != ''">
										<span>内訳 : </span>
										<span v-html="yoichiItem.timetable"></span>
									</div>


									<div class="prohibited" v-show="yoichiItem.reminder_icon_flg === 1">
										<ul class="prohibited__list">
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_a.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_b.svg" alt=""></li>
											<li class="prohibited__item"><img src="../assets/img/icon/icon-prohibited_c.svg" alt=""></li>
										</ul>
									</div>

									
									<div class="eventsItemsSubItem_details-tags">
										<p v-if="yoichiItem.type == 8" class="eventsItemsSubItem_details-tag _pay">無料</p>
										<p v-else-if="yoichiItem.type == 9" class="eventsItemsSubItem_details-tag _pay">有料</p>
										<p class="eventsItemsSubItem_details-tag _test" v-show="yoichiItem.tag_1" v-html="yoichiItem.tag_1"></p>
										<p class="eventsItemsSubItem_details-tag _visit" v-show="yoichiItem.tag_2" v-html="yoichiItem.tag_2"></p>
									</div>
									<div class="eventsItemsSubItem_details-link js-modal_openBtn" v-show="yoichiItem.detail_disp_flg == true" @click="eventDetail(1001, yoichiItem.course_id)">
										<span class="eventsItemsSubItem_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsSubItem_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646">
												<g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsSubItem_details-caution u-font_notoSans" v-show="yoichiItem.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</div>
						</div>
					</div>

					<div class="eventsItems_inner" id="miyagikyoEvents" v-show="eventflg == true || mygflg == true">
						<div class="eventsItems_top mincho">
							宮城峡蒸溜所
						</div>
						<!-- <p class="eventsItemsMain_pause">【只今セミナーは休止しております。再開に関しては、改めてご案内いたします。】</p> -->
						<ul>
							<li class="eventsItems_main _miyagikyo" v-for="miyagikyoPickup in miyagikyoMain" :key="miyagikyoPickup.course_id">
								<div class="eventsItemsMain_inner">
									<div class="eventsItemsMain_img" v-show="miyagikyoPickup.image">
										<img :src="`/storage/${miyagikyoPickup.image}`" alt="">
										<p class="eventsItemsMain_img-txt mincho">PICK UP</p>
									</div>
									<div class="eventsItemsMain_details">
										<div class="eventsItemsMain_details-openDate _miyagikyo">
											<span>開催日 : </span>
											<span v-html="miyagikyoPickup.course_date"></span>
										</div>
										<div class="eventsItemsMain_details-ttl" v-html="miyagikyoPickup.name"></div>
										<div class="eventsItemsMain_details-desc" v-show="miyagikyoPickup.overview != ''" v-html="miyagikyoPickup.overview"></div>
										<div class="eventsItemsMain_details-fee" v-show="miyagikyoPickup.fee != ''">
											<span>参加費 : </span>
											<span v-html="miyagikyoPickup.fee"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTime">
											<span>所要時間 : </span>
											<span v-html="miyagikyoPickup.total_time"></span>
										</div>
										<div class="eventsItemsMain_details-requiredTimeText" v-show="miyagikyoPickup.timetable != ''">
											<span>内訳 : </span>
											<span v-html="miyagikyoPickup.timetable"></span>
										</div>
									</div>
								</div>
								<div class="eventsItemsMain_outer">
									<div class="eventsItemsMain_details-tags">
										<p v-if="miyagikyoPickup.type == 3" class="eventsItemsMain_details-tag _pay">無料</p>
										<p v-else-if="miyagikyoPickup.type == 4" class="eventsItemsMain_details-tag _pay">有料</p>
										<p class="eventsItemsMain_details-tag _test" v-show="miyagikyoPickup.tag_1" v-html="miyagikyoPickup.tag_1"></p>
										<p class="eventsItemsMain_details-tag _visit" v-show="miyagikyoPickup.tag_2" v-html="miyagikyoPickup.tag_2"></p>
									</div>
									<div class="eventsItemsMain_details-link js-modal_openBtn" v-show="miyagikyoPickup.detail_disp_flg == true" @click="eventDetail(1000, miyagikyoPickup.course_id)">
										<span class="eventsItemsMain_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsMain_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646"><g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsMain_details-caution" v-show="miyagikyoPickup.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</li>
						</ul>
					
						<div class="eventsItems_sub _miyagikyo">
							<div class="eventsItemsSub_item" v-for="miyagikyoItem in miyagikyoCourses" :key="miyagikyoItem.course_id">
								<div class="eventsItemsSubItem_imgs">
									<div class="eventsItemsSubItem_img" v-show="miyagikyoItem.image">
										<img :src="`/storage/${miyagikyoItem.image}`" alt="">
										<p class="eventsItemsSubItem_img-txt mincho" v-show="miyagikyoItem.pickup_flg == 1">PICK UP</p>
									</div>
									<div class="eventsItemsSubItem_details-openDate _miyagikyo">
										<span>開催日 : </span>
										<span v-html="miyagikyoItem.course_date"></span>
									</div>
								</div>
								<div class="eventsItemsSubItem_details">
									<div class="eventsItemsSubItem_details-ttl" v-html="miyagikyoItem.name"></div>
									<div class="eventsItemsSubItem_details-desc" v-show="miyagikyoItem.overview != ''" v-html="miyagikyoItem.overview"></div>
									<div class="eventsItemsSubItem_details-fee" v-show="miyagikyoItem.fee != ''">
										<span>参加費 : </span>
										<span v-html="miyagikyoItem.fee"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTime">
										<span>所要時間 : </span>
										<span v-html="miyagikyoItem.total_time"></span>
									</div>
									<div class="eventsItemsSubItem_details-requiredTimeText" v-show="miyagikyoItem.timetable != ''">
										<span>内訳 : </span>
										<span v-html="miyagikyoItem.timetable"></span>
									</div>
									<div class="eventsItemsSubItem_details-tags">
										<p v-if="miyagikyoItem.type == 3" class="eventsItemsSubItem_details-tag _pay">無料</p>
										<p v-else-if="miyagikyoItem.type == 4" class="eventsItemsSubItem_details-tag _pay">有料</p>
										<p class="eventsItemsSubItem_details-tag _test" v-show="miyagikyoItem.tag_1" v-html="miyagikyoItem.tag_1"></p>
										<p class="eventsItemsSubItem_details-tag _visit" v-show="miyagikyoItem.tag_2" v-html="miyagikyoItem.tag_2"></p>
									</div>
									<div class="eventsItemsSubItem_details-link js-modal_openBtn" v-show="miyagikyoItem.detail_disp_flg == true" @click="eventDetail(1000, miyagikyoItem.course_id)">
										<span class="eventsItemsSubItem_details-link_txt u-font_notoSans">詳しく見る</span>
										<span class="eventsItemsSubItem_details-link_arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.492 14.646">
												<g transform="translate(147.676 74.522) rotate(90)"><g transform="translate(-74.345 125.36)"><path d="M-73.939,132.914l-.407-.407,7.146-7.147,7.146,7.146-.407.407-6.74-6.74Z" transform="translate(74.345 -125.36)" fill="#333" stroke="#333" stroke-width="0.25" /></g><g transform="translate(-67.487 125.767)"><rect width="0.575" height="21.784" fill="#333" stroke="#333" stroke-width="0.25" /></g></g>
											</svg>
										</span>
									</div>
									<p class="eventsItemsSubItem_details-caution" v-show="miyagikyoItem.detail_disp_flg == false">※セミナーの再開に関しては、改めてご案内いたします。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- イベント一覧 end -->

				<!-- バナー start -->
				<div class="events_banner eventsBanner">
					<div class="eventsBanner_img">
						<a href="https://www.nikka.com/distilleries/experience/"><img src="../assets/img/event/img_banner.png" alt=""></a>
					</div>
				</div>
				<!-- バナー end -->

				<!-- トップに戻るボタン start -->
				<div class="events_links eventsLinks" v-show="yoichibefore == false">
					<div class="eventsLinks_item">
						<router-link tag="a" to="/miyagikyo/reservation" target="_blank">宮城峡蒸溜所 見学予約</router-link>
					</div>
					<div class="eventsLinks_item">
						<router-link tag="a" to="/yoichi/reservation" target="_blank">余市蒸溜所 見学予約</router-link>
					</div>
				</div>
				<div class="events_links eventsLinks" v-show="yoichibefore == true || yoichiflg == true">
					<div class="eventsLinks_item">
						<router-link tag="a" to="/yoichi/reservation" target="_blank">余市蒸溜所 見学予約</router-link>
					</div>
					<div class="eventsLinks_item">
						<router-link tag="a" to="/miyagikyo/reservation" target="_blank">宮城峡蒸溜所 見学予約</router-link>
					</div>
				</div>
				<!-- トップに戻るボタン end -->

				<!-- お問い合わせ start -->
				<div class="events_contact eventsContact" v-show="eventflg == true && yoichibefore == false">
					<div class="eventsContact_ttl u-font_notoSans">※見学等に関するお問い合わせ先</div>
					<div class="eventsContact_txt">
						<a href="tel:0223952865" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">022-395-2865 <span>(宮城峡蒸溜所)</span></span>
						</a>
						<a href="tel:0135233131" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">0135-23-3131 <span>(余市蒸溜所)</span></span>
						</a>
						<br>
						<div class="eventsContact_time u-font_notoSans">
							<div class="eventsContact_time_ttl">お電話受付時間 </div>
							<div class="plantTimes">
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>宮城峡蒸溜所 9:00～16:00<br class="u-sp">（休業日除く）</span>
								</div>
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>余市蒸溜所 9:00～16:15<br class="u-sp">（休業日除く）</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="events_contact eventsContact" v-show="eventflg == true && yoichibefore == true">
					<div class="eventsContact_ttl u-font_notoSans">※見学等に関するお問い合わせ先</div>
					<div class="eventsContact_txt">
						<a href="tel:0135233131" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">0135-23-3131 <span>(余市蒸溜所)</span></span>
						</a>
						<a href="tel:0223952865" class="eventsContact_phone">
							<img src="../assets/img/telIcon.png" alt="">
							<span class="u-font_notoSans">022-395-2865 <span>(宮城峡蒸溜所)</span></span>
						</a>
						<br>
						<div class="eventsContact_time u-font_notoSans">
							<div class="eventsContact_time_ttl">お電話受付時間 </div>
							<div class="plantTimes">
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>余市蒸溜所 9:00～16:15（休業日除く）</span>
								</div>
								<div class="plantTime">
									<span class="u-pc">:</span>
									<span>宮城峡蒸溜所 9:00～16:00（休業日除く）</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- お問い合わせ end -->
			</div>
			<Tel v-show="eventflg == false" />
		</template>
  </div>
</template>

<script>
import MainVisual from './MainVisual'
import Tel from './Tel'
import moment from 'moment'
import axios from 'axios'

export default {
	name: 'EventInfo',
  components: {
    MainVisual,
    Tel
  },
	data () {
    return {
			mygflg: false,
			yoichiflg: false,
			eventflg: false,
			yoichibefore: false,
			loading: true,
			plantName: "",
			head: window.$("head"),
			ageScript: document.createElement('script'),
			flgParam: '',
			url: "/api/reserveSlot/list",
			courseInfo_m: null,
			courseInfo_y: null,
			yoichiMain: '',
			miyagikyoMain: '',
			yoichiCourses: [],
			miyagikyoCourses: [],
			yoichiAll: [],
			miyagikyoAll: [],
			maintenance_flag:false
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'イベント一覧'
			}
		},
		// link: [
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    // ],
    // script: [
    //   { type: 'text/javascript', src: '/include/include.js' }
    // ]
	},
	mounted() {
		// 一番上まで移動
		window.$("body, html").scrollTop(0);
		
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
			this.plantName = '宮城峡蒸溜所';
		}else if(location.pathname.indexOf('yoichi') != -1) {
			this.yoichiflg = true;
			this.plantName = '余市蒸溜所';
		}else {
			this.eventflg = true;
			this.plantName = '宮城峡・余市蒸溜所';
		}
		
		// タイトルアップデート
		this.$emit('updateHead');

		// 年齢確認スクリプトを読み込ませる
		this.ageScript.setAttribute("src", "/agecheck/js/agechk_nikka.js");
		this.head.append(this.ageScript);

		this.flgParam = this.$route.query.tgt;
		if(this.flgParam == 'y') {
			this.yoichibefore = true;
		}else {
			this.yoichibefore = false;
		}
		
		this.getAllEvents();

		// メンテナンス時の表示切替用フラグをjsonから取得
    axios.get("/files/maintenance.json").then((res) => {
      this.maintenance_flag = res.data.data.maintenance_flag;
		});
		
	},
	methods: {
		// イベントを表示する処理
		getAllEvents() {
			// 余市イベント
			if(this.yoichiflg == true || this.eventflg == true) {
				axios({
					method: "post",
					url: this.url,
					headers: {
						"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
					},
					data: {
						plant_id: 1001,
						display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM")
					}
				}).then(res => {
					var courses = res.data.data.course_info;
					this.yoichiAll = courses.filter((result) => {
						return result["type"] == 3 || result["type"] == 4 || result["type"] == 8 || result["type"] == 9;
					});
					// this.yoichiAll = courses.filter((result) => {
					// 	return result["paid_flg"] == 1;
					// });
					this.sortEventsByDate();
					// メイン
					this.yoichiMain = this.yoichiAll.filter((result) => {
						return result["pickup_flg"] == 1;
					});
					// サブ
					this.yoichiCourses = this.yoichiAll.filter((result) => {
						return result["pickup_flg"] != 1;
					});

					// 読み込み状態解除
					this.loading = false;
				});
			// 	axios.get(this.yoichiUrl)
			// 	.then(res => {
			// 		var courses = res.data.data.course_info;
			// 		this.yoichiAll = courses.filter((result) => {
			// 			return result["pickup_flg"] == 1;
			// 		});
			// 		this.sortEventsByDate();
			// 		// メイン
			// 		this.yoichiMain = this.yoichiAll[0];
			// 		// サブ
			// 		for (var i = 1; i < this.yoichiAll.length; i++) {
			// 			this.yoichiCourses.push(courses[i]);
			// 		}
			// 	});
			}

			if(this.mygflg == true || this.eventflg == true) {
				// 宮城峡イベント
				axios({
					method: "post",
					url: this.url,
					headers: {
						"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
					},
					data: {
						plant_id: 1000,
						display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM")
					}
				}).then(res => {
					var courses = res.data.data.course_info;

					this.miyagikyoAll = courses.filter((result) => {
						return result["type"] == 3 || result["type"] == 4 || result["type"] == 8 || result["type"] == 9;
					});

					// this.miyagikyoAll = courses.filter((result) => {
					// 	// console.log(result["paid_flg"]);
					// 	if (result["paid_flg"] == 1) {
					// 		console.log(result);
					// 	}
					// 	return result["paid_flg"] == 1;
					// });

					this.sortEventsByDate();
					// メイン
					this.miyagikyoMain = this.miyagikyoAll.filter((result) => {
						return result["pickup_flg"] == 1;
					});
					// サブ
					this.miyagikyoCourses = this.miyagikyoAll.filter((result) => {
						return result["pickup_flg"] != 1;
					});
				});
				// axios.get(this.miyagikyoUrl)
				// .then(res => {
				// 	var courses = res.data.data.course_info;
				// 	this.miyagikyoAll = courses.filter((result) => {
				// 		return result["pickup_flg"] == 1;
				// 	});
				// 	this.sortEventsByDate();
				// 	// メイン
				// 	this.miyagikyoMain = this.miyagikyoAll[0];
				// 	// サブ
				// 	for (var j = 1; j < this.miyagikyoAll.length; j++) {
				// 		this.miyagikyoCourses.push(courses[j]);
				// 	}
				// });
			}
		},

		sortEventsByDate() {
			// 余市
			this.yoichiCourses.sort((a, b) => {
				return moment(b.course_start_date) - moment(a.course_start_date);
			});

			// 宮城峡
			this.miyagikyoCourses.sort((a, b) => {
				return moment(b.course_start_date) - moment(a.course_start_date);
			});
		},

		// イベントごとにアンカーリンクで行く処理 start
		yoichiAnchorEvents() {
			window.$("html, body").animate({
				scrollTop: window.$('#yoichiEvents').offset().top
			}, 700);
		},

		miyagikyoAnchorEvents() {
			window.$("html, body").animate({
				scrollTop: window.$('#miyagikyoEvents').offset().top
			}, 700);
		},

		eventDetail(plant_id, event_id) {
			if(plant_id == 1000) {
				localStorage.setItem('courseInfo', JSON.stringify(this.miyagikyoAll));
			}else {
				localStorage.setItem('courseInfo', JSON.stringify(this.yoichiAll));
			}
			this.$router.push({ path: '/event/detail', query: { plant_id: plant_id, event_id: event_id } });
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reservation.is-maintenance {
  background-color: transparent!important;
  color: #000;
}
.maintenance {
  padding: 80px 0;
  &_ttl {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 0.05em;
    line-height: 1.5 !important;
  }
  &_txt {
    font-size: 18px;
    line-height: 2 !important;
    margin-bottom: 15px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  &_end {
    width: 455px;
    box-sizing: border-box;
    font-size: 20px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #df1016;
    border: 1px solid #df1016;
    border-radius: 6px;
    padding: 10px 0;
    margin: 0 auto 30px;
  }
  &_last {
    font-size: 18px;
    line-height: 2 !important;
    text-align: center;
    margin-bottom: 80px;
  }
}

// 768以下
.picxel .maintenance {
  padding-top: 40px;
  &_ttl {
    width: calc(100% - 50px);
    font-size: 26px;
    margin: 0 auto 30px;
  }
  &_txt {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 14px;
    text-align: left;
  }
  &_end {
    width: calc(100% - 50px);
    margin: 0 auto 15px;
    font-size: 16px;
    line-height: 1.8 !important;
    padding: 5px 20px;
  }
  &_last {
    width: calc(100% - 50px);
    margin: 0 auto 50px;
    font-size: 14px;
    text-align: left;
  }
}
</style>
<style lang="scss">
.container {
	overflow: hidden;
	margin: 0 auto;
	width: 1010px;
	padding-bottom: 40px;
}

// 768以下
.picxel .container {
	width: 270px;
}

.u-pc {
	display: block;
	@media screen and (max-width: 768px) {
		display: none;
	}
}

// 768以下
.picxel .u-pc {
	display: none;
}

.u-sp {
	display: none;
	@media screen and (max-width: 768px) {
		display: block;
	}
}

// 768以下
.picxel .u-sp {
	display: block;
}

// お知らせ
// .noticeBox {
// 	width: 100%;
// 	background-color: #FFF;
// 	padding: 41px 0 39px;
// }

// 768以下
// .picxel .noticeBox {
// 	padding: 78px 0 80px;
// }

// .noticeSection {
// 	width: 1010px;
// 	margin: 0 auto;
// 	box-sizing: border-box;
// 	padding: 22px 66px 27px;
// 	border: 2px solid #E51117;
// 	border-radius: 5px;
// 	&_ttl {
// 		font-size: 22px;
// 		text-align: center;
// 		letter-spacing: 0.075em;
// 		margin-bottom: 8px;
// 	}
// }

// 768以下
// .picxel .noticeSection {
// 	width: calc(100% - 100px);
// 	padding: 52px 30px 60px;
// 	&_ttl {
// 		font-size: 40px;
// 		margin-bottom: 10px;
// 	}
// }

// .win .mainFont .noticeContent {
// 	&_ttl {
// 		font-size: 17px;
// 		text-align: center;
// 		letter-spacing: 0.075em;
// 		color: #E51117;
// 		margin-bottom: 10px;
// 	}
// 	&_txt {
// 		font-size: 14px;
// 		line-height: 1.8;
// 	}
// }

// 768以下
// .picxel.win .mainFont .noticeContent {
// 	&_ttl {
// 		font-size: 32px;
// 		line-height: 1.8;
// 		margin-bottom: 20px;
// 	}
// 	&_txt {
// 		font-size: 26px;
// 	}
// }

.events_top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	-webkit-box-pack: center;
			-ms-flex-pack: center;
					justify-content: center;
	background: url("/event/images/img_top.png") center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 300px;
}

// 768以下
.picxel .events_top {
	background: url("/event/images/img_top.png") 70%;
	background-size: cover;
	background-repeat: no-repeat;
	height: 200px;
}

.events_top .eventsTop_ttl {
	font-size: 40px;
	color: #fff;
	letter-spacing: 6px;
	font-weight: bold;
}

// 768以下
.picxel .events_top .eventsTop_ttl {
	font-size: 24px;
}

.events_links {
	margin-top: 97px;
}

// 768以下
.picxel .events_links {
	margin-top: 58px;
}

.win .mainFont .events_links .eventsLinks_ttl {
	position: relative;
	margin: 0 auto;
	text-align: center;
	font-size: 26px;
	letter-spacing: 2px;
	font-weight: bold;
	padding-bottom: 16px;
	@media screen and (max-width: 768px) {
		line-height: 1.4 !important;
		width: 500px;
		font-size: 40px;
		padding-bottom: 32px;
	}
}

// 768以下
.picxel.win .mainFont .events_links .eventsLinks_ttl {
	line-height: 1.4 !important;
	width: 250px;
	font-size: 20px;
	padding-bottom: 16px;
}

.events_links .eventsLinks_ttl::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: #000;
}

.events_links .eventsLinks_btns {
	width: 670px;
	margin: 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
	margin-top: 40px;
	@media screen and (max-width: 768px) {
		width: 100%;
		margin-top: 60px;
	}
}

// 768以下
.picxel .events_links .eventsLinks_btns {
	width: 100%;
	margin-top: 30px;
}

.events_links .eventsLinks_btns .eventsLinksBtns_item {
	cursor: pointer;
	position: relative;
	width: calc(50% - 16px);
	text-align: center;
	letter-spacing: 2px;
	font-size: 18px;
	font-weight: bold;
	color: #000;
	border: 1px solid #000;
	padding: 24px 0;
	background: #fff;
	-webkit-transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.events_links .eventsLinks_btns .eventsLinksBtns_item:hover {
	color: #fff;
	background: #000;
}

.events_links .eventsLinks_btns .eventsLinksBtns_item:hover svg path {
	stroke: #fff;
}

.events_links .eventsLinks_btns .eventsLinksBtns_item svg path {
	-webkit-transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

// 768以下
.picxel .events_links .eventsLinks_btns .eventsLinksBtns_item {
	width: calc(50% - 10px);
	font-size: 14px;
	padding: 20px 0 10px;
}

.events_links .eventsLinks_btns .eventsLinksBtns_arrow {
	position: absolute;
	top: 50%;
	right: 20px;
	-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
}

// 768以下
.picxel .events_links .eventsLinks_btns .eventsLinksBtns_arrow {
	position: static;
	-webkit-transform: none;
					transform: none;
	display: block;
	margin-top: 10px;
	text-align: center;
}

.events_links .eventsLinks_btns .eventsLinksBtns_arrow svg {
	width: 14px;
}

// 768以下
.picxel .events_links .eventsLinks_btns .eventsLinksBtns_arrow svg {
	width: 10px;
}
.events_items{
	max-width: 1000px;
	margin: 0 auto;
}
.events_items img {
	width: 100%;
}

.events_items .eventsItems_inner {
	padding-top: 116px;
}

.events_items .eventsItems_inner:first-child {
	padding-top: 120px;
}

.events_items .eventsItems_top {
	margin-bottom: 48px;
	font-size: 28px;
	font-weight: bold;
	padding-bottom: 16px;
	border-bottom: 2px solid #E8E8E8;
}

// 768以下
.picxel .events_items .eventsItems_top {
	font-size: 20px;
	padding-bottom: 15px;
	border-bottom: 2px solid #E8E8E8;
}

// .eventsItemsMain_pause {
// 	width: 100%;
// 	font-size: 14px;
// 	font-weight: bold;
// 	text-align: center;
// 	margin: -28px 0 20px;
// 	color: #ff0000;
// }

// 768以下
// .picxel .eventsItemsMain_pause {
// 	font-size: 26px;
// 	text-align: left;
// 	margin-bottom: 70px;
// }

.events_items .eventsItems_main {
	margin-top: 60px;
}

// 768以下
.picxel .events_items .eventsItems_main {
	margin-top: 40px;
}

.events_items .eventsItems_main:first-child {
	margin-top: 0;
}

// 768以下
.picxel .events_items .eventsItems_main:first-child {
	margin-top: 0;
}

.events_items .eventsItems_main .eventsItemsMain_inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_inner {
	display: block;
}

.events_items .eventsItems_main .eventsItemsMain_img {
	position: relative;
	width: 420px;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_img {
	width: 100%;
}

.events_items .eventsItems_main .eventsItemsMain_img .eventsItemsMain_img-txt {
	position: absolute;
	top: -13px;
	left: 0;
	font-size: 16px;
	padding: 6px 30px;
	background: #000;
	color: #fff;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_img .eventsItemsMain_img-txt {
	top: -10px;
	font-size: 12px;
	padding: 5px 18px;
}

.events_items .eventsItems_main .eventsItemsMain_details {
	width: calc(100% - 440px);
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details {
	width: 100%;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-openDate {
	font-size: 15px;
	font-weight: 350;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-openDate {
	font-size: 12px;
	margin-top: 10px;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-openDate._yoichi {
	color: #99070B;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-openDate._miyagikyo {
	color: #166B0E;
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-ttl {
	font-size: 20px;
	line-height: 1.4 !important;
	margin: 3px 0 6px;
	font-weight: 700;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-ttl {
	font-size: 20px;
	margin: 10px 0;
	line-height: 1.6 !important;
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-desc {
	font-size: 14px;
	line-height: 1.4 !important;
	padding-bottom: 15px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-desc {
	font-size: 14px;
	line-height: 1.6 !important;
	padding-bottom: 12px;
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-fee {
	position: relative;
	padding-top: 15px;
	font-weight: bold;
	line-height: 1.6 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-fee {
	padding-top: 14px;
	font-size: 12px;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-fee::after {
	content: "";
	background: #E5E5E5;
	width: 20px;
	height: 2px;
	position: absolute;
	top: 0;
	left: 0;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-fee::after {
	width: 20px;
	height: 2px;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-fee p {
	display: inline;
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTime {
	margin-top: 10px;
	font-weight: bold;
	line-height: 1.6 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTime {
	font-size: 12px;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTime p {
	display: inline;
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTimeText {
	margin-top: 10px;
	font-weight: bold;
	line-height: 1.9 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTimeText {
	font-size: 12px;
}

.events_items .eventsItems_main .eventsItemsMain_details .eventsItemsMain_details-requiredTimeText p {
	display: inline;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag {
	margin-top: 12px;
	border-radius: 18px;
	font-size: 13px;
	padding: 10px 18px;
	margin-right: 10px;
	background-color: #FFF;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag {
	margin-top: 15px;
	font-size: 12px;
	padding: 10px 15px;
	border-radius: 16px;
	margin-right: 10px;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag:last-child {
	margin-right: 0px;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag._pay {
	border: 1px solid #A0895E;
	color: #A0895E;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag._test {
	border: 1px solid #CE6C5E;
	color: #CE6C5E;
}

.events_items .eventsItems_main .eventsItemsMain_details-tags .eventsItemsMain_details-tag._visit {
	border: 1px solid #0F2784;
	color: #0F2784;
}

.events_items .eventsItems_main .eventsItemsMain_outer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_outer {
	display: block;
}

.events_items .eventsItems_main .eventsItemsMain_details-link {
	cursor: pointer;
	width: 200px;
	position: relative;
	margin-top: 25px;
	border-bottom: 1px solid #333333;
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details-link {
	width: 120px;
	margin-top: 16px;
	padding-bottom: 10px;
}

.events_items .eventsItems_main .eventsItemsMain_details-link:hover .eventsItemsMain_details-link_arrow {
	-webkit-transform: translateX(-5px) translate(-50%, -50%);
					transform: translateX(-5px) translate(-50%, -50%);
}

.events_items .eventsItems_main .eventsItemsMain_details-link .eventsItemsMain_details-link_txt {
	pointer-events: none;
	font-size: 14px;
	display: inline-block;
	/* -webkit-transform: translateY(-5px);
					transform: translateY(-5px); */
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details-link .eventsItemsMain_details-link_txt {
	font-size: 12px;
	-webkit-transform: translateY(3px);
					transform: translateY(3px);
}

.events_items .eventsItems_main .eventsItemsMain_details-link .eventsItemsMain_details-link_arrow {
	pointer-events: none;
	width: 20px;
	position: absolute;
	top: 50%;
	right: -10px;
	-webkit-transform: translateX(0) translate(-50%, -50%);
					transform: translateX(0) translate(-50%, -50%);
	-webkit-transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	@media screen and (max-width: 768px) {
		width: 40px;
		top: calc(50%);
	}
}

// 768以下
.picxel .events_items .eventsItems_main .eventsItemsMain_details-link .eventsItemsMain_details-link_arrow {
	width: 20px;
	top: calc(50%);
}

.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details-caution {
	font-size: 11px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
			-ms-flex-align: end;
					align-items: flex-end;
	color: #333333;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details-caution {
	margin-top: 15px;
	font-size: 11px;
	line-height: 1.6 !important;
}

.events_items .eventsItems_sub {
	width: calc(100% + 20px);
	margin-top: 40px;
	margin-left: -20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
	border-top: 1px solid #E8E8E8;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

// 768以下
.picxel .events_items .eventsItems_sub {
	border-top: none;
	width: 100%;
	margin-left: 0;
	display: block;
}

.events_items .eventsItems_sub::before, .events .events_items .eventsItems_sub::after {
	content: "";
	display: block;
	width: 33.333%;
	height: 0;
}

.events_items .eventsItems_sub::before {
	-webkit-box-ordinal-group: 2;
			-ms-flex-order: 1;
					order: 1;
}

.events_items .eventsItems_sub .eventsItemsSub_item {
	position: relative;
	width: 33.333%;
	padding: 30px 20px;
	-webkit-box-sizing: border-box;
					box-sizing: border-box;
	border-left: 1px solid #E8E8E8;
	border-right: 1px solid #E8E8E8;
	border-bottom: 1px solid #E8E8E8;
	font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho ProN", "MS P 明朝", "MS PMincho", serif !important;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item {
	width: 100%;
	padding: 20px 0;
	border-top: 1.5px solid #E8E8E8;
	border-left: none;
	border-right: none;
	border-bottom: none;
}

.events_items .eventsItems_sub .eventsItemsSub_item:nth-of-type(1n+1) {
	border-left: none;
}

.events_items .eventsItems_sub .eventsItemsSub_item:nth-of-type(1n+1) .eventsItemsSubItem_img .eventsItemsSubItem_img-txt {
	left: 20px !important;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item:nth-of-type(1n+1) .eventsItemsSubItem_img .eventsItemsSubItem_img-txt {
	left: 0 !important;
}

.events_items .eventsItems_sub .eventsItemsSub_item:nth-of-type(3n+3) {
	border-right: none;
	padding-right: 0;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item:last-child {
	border-bottom: 1.5px solid #E8E8E8;
}

.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_imgs {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_img {
	max-width: 300px;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_img {
	width: 60%;
	max-width: 250px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_img img {
	width: 100%;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_img .eventsItemsSubItem_img-txt {
	display: block;
	position: absolute;
	top: 15px;
	left: 0;
	font-size: 16px;
	padding: 6px 30px;
	background: #AF9754;
	color: #fff;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_img .eventsItemsSubItem_img-txt {
	font-size: 12px;
	padding: 5px 17px;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_imgs .eventsItemsSubItem_details-openDate {
	font-size: 13px;
	font-weight: 350;
	margin-top: 13px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_imgs .eventsItemsSubItem_details-openDate {
	width: calc(40% - 10px);
	font-size: 12px;
	line-height: 1.6 !important;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_imgs .eventsItemsSubItem_details-openDate._yoichi {
	color: #99070B;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_imgs .eventsItemsSubItem_details-openDate._miyagikyo {
	color: #166B0E;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-ttl {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.4 !important;
	margin-top: 13px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-ttl {
	font-size: 14px;
	line-height: 1.6 !important;
	margin-top: 10px;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-desc {
	font-size: 14px;
	line-height: 1.6 !important;
	margin-top: 9px;
	padding-bottom: 15px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-desc {
	font-size: 14px;
	margin-top: 12px;
	padding-bottom: 12px;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-fee {
	position: relative;
	padding-top: 15px;
	font-weight: bold;
	line-height: 1.6 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-fee {
	padding-top: 12px;
	font-size: 12px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-fee::after {
	content: "";
	background: #E5E5E5;
	width: 20px;
	height: 2px;
	position: absolute;
	top: 0;
	left: 0;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-fee::after {
	width: 20px;
	height: 2px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-fee p {
	display: inline;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTime {
	margin-top: 10px;
	font-weight: bold;
	line-height: 1.6 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTime {
	font-size: 12px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTime p {
	display: inline;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTimeText {
	margin-top: 10px;
	font-weight: bold;
	line-height: 1.9 !important;
	font-size: 14px;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTimeText {
	font-size: 12px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-requiredTimeText p {
	display: inline;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags {
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	width: 100%;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag {
	margin-top: 20px;
	border-radius: 18px;
	font-size: 13px;
	padding: 6px 14px;
	margin-right: 7px;
	background-color: #FFF;
}
.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag:last-child{
	margin-right: 0;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag {
	margin-top: 15px;
	margin-right: 10px;
	display: inline;
	font-size: 12px;
	padding: 7px 10px;
	border-radius: 18px;
	line-height: 1.6 !important;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag:last-child {
	margin-right: 0px;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag:last-child {
	margin-right: 0px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag._pay {
	border: 1px solid #A0895E;
	color: #A0895E;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag._test {
	border: 1px solid #CE6C5E;
	color: #CE6C5E;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-tags .eventsItemsSubItem_details-tag._visit {
	border: 1px solid #0F2784;
	color: #0F2784;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link {
	cursor: pointer;
	width: 103px;
	position: relative;
	margin-top: 25px;
	padding-bottom: 14px;
	border-bottom: 1px solid #000;
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link {
	width: 125px;
	margin-top: 15px;
	padding-bottom: 10px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link .eventsItemsSubItem_details-link_txt {
	pointer-events: none;
	font-size: 14px;
	display: inline-block;
	-webkit-transform: translateY(3px);
					transform: translateY(3px);
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link .eventsItemsSubItem_details-link_txt {
	font-size: 12px;
}

.events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link .eventsItemsSubItem_details-link_arrow {
	pointer-events: none;
	width: 16px;
	position: absolute;
	top: 50%;
	right: -6px;
	-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
}

// 768以下
.picxel .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-link .eventsItemsSubItem_details-link_arrow {
	width: 20px;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-caution {
	margin-top: 15px;
	font-size: 11px;
	line-height: 1.6 !important;
	color: #333333;
}

// 768以下
.picxel.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .eventsItemsSubItem_details-caution {
	margin-top: 15px;
	font-size: 11px;
}

.events_banner {
	margin-top: 80px;
}

.events_banner .eventsBanner_img {
	cursor: pointer;
	width: 600px;
	margin: 0 auto;
	transition: opacity .3s ease-out;
	&:hover {
		opacity: .7;
	}
}

// 768以下
.picxel .events_banner .eventsBanner_img {
	width: 100%;
}

.events_banner .eventsBanner_img a {
	display: block;
}

.events_banner .eventsBanner_img img {
	width: 100%;
}

.events_links {
	margin: 80px auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
	width: 100%;
	max-width: 660px;
}

// 768以下
.picxel .events_links {
	margin-top: 58px;
}

.events_links .eventsLinks_item {
	width: calc(50% - 10px);
	margin: 0 auto;
}

// 768以下
.picxel .events_links .eventsLinks_item {
	width: 100%;
	&:nth-of-type(2) {
		margin-top: 10px;
	}
}

.events_links .eventsLinks_item a {
	display: block;
	padding: 25px 0;
	font-size: 18px;
	font-weight: medium;
	text-align: center;
	color: #fff;
	border: 1px solid #000;
	background: #000;
	-webkit-transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	position: relative;
}

// 768以下
.picxel .events_links .eventsLinks_item a {
	padding: 20px 0;
	font-size: 15px;
}

.events_links .eventsLinks_item a::after {
	content: "";
	display: block;
	width: 6px;
	height: 6px;
	border: solid #000;
	border-width: 1px 1px 0 0;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translate(0, -50%) rotate(45deg);
					transform: translate(0, -50%) rotate(45deg);
}

.events_links .eventsLinks_item a::after {
	border-color: #fff;
	right: 30px;
	width: 8px;
	height: 8px;
	-webkit-transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.events_links .eventsLinks_item a:hover {
	background: #fff;
	color: #000;
}

.events_links .eventsLinks_item a:hover::after {
	border-color: #000;
}

.events_contact {
	width: 600px;
	margin: 80px auto 0;
	text-align: center;
	border: 1px solid #EBEBEB;
	background: #fff;
}

// 768以下
.picxel .events_contact {
	width: 100%;
}

.events_contact .eventsContact_ttl {
	font-size: 16px;
	line-height: 1 !important;
	font-weight: bold;
	background: #080808;
	padding: 18px 0;
	color: #fff;
}

// 768以下
.picxel .events_contact .eventsContact_ttl {
	font-size: 15px;
	padding: 20px 0;
}

.events_contact .eventsContact_txt {
	padding: 12px 0 20px;
}

// 768以下
.picxel .events_contact .eventsContact_txt {
	padding: 15px 0 24px;
}

.events_contact .eventsContact_txt a {
	margin-top: 10px;
}

.events_contact .eventsContact_phone {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	-webkit-box-pack: center;
			-ms-flex-pack: center;
					justify-content: center;
}

.events_contact .eventsContact_phone:first-child {
	-webkit-transform: translateX(-6px);
					transform: translateX(-6px);
}

// 768以下
.picxel .events_contact .eventsContact_phone:first-child {
	-webkit-transform: translateX(0);
					transform: translateX(0);
}

.events_contact .eventsContact_phone img {
	width: 30px;
	height: auto;
	display: block;
	-webkit-transform: translateY(-1px);
					transform: translateY(-1px);
}

// 768以下
.picxel .events_contact .eventsContact_phone img {
	width: 40px;
	-webkit-transform: translateY(-8px);
					transform: translateY(-8px);
}

.win .mainFont .events_contact .eventsContact_phone .u-font_notoSans {
	padding-left: 12px;
	font-size: 26px;
	font-weight: bold;
	line-height: 1 !important;
}

// 768以下
.picxel.win .mainFont .events_contact .eventsContact_phone span {
	font-size: 14px;
	line-height: 1.5 !important;
}

.events_contact .eventsContact_phone span > span {
	font-size: 14px;
}

// 768以下
.picxel .events_contact .eventsContact_phone span > span {
	text-align: left;
	font-size: 14px;
	padding-left: 18px;
	display: block;
}

.win .mainFont .events_contact .eventsContact_time {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	font-size: 16px;
	padding-top: 10px;
	line-height: 1 !important;
}

// 768以下
.picxel.win .mainFont .events_contact .eventsContact_time {
	display: block;
	font-size: 14px;
	padding-top: 18px;
	line-height: 1.6 !important;
}

.win .mainFont .events_contact .eventsContact_time .plantTime {
	display: flex;
}

.picxel.win .mainFont .events_contact .eventsContact_time .plantTime {
	display: block;
	margin-top: 7.5px;
}

.win .mainFont .events_contact .eventsContact_time .plantTime .u-pc {
	padding: 0 5px;
}

//add to 2403
.win .mainFont .events_items .eventsItems_main .eventsItemsMain_details .prohibited{
	margin-top: 24px;
	&__list{
		display: flex;
		gap: 0 12px;
	}
	&__item{
		width: 42px;
	}
}
.win.picxel .mainFont .events_items .eventsItems_main .eventsItemsMain_details .prohibited{
	margin-top: 8px;
}

.win .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .prohibited{
	margin-top: 16px;
	&__list{
		display: flex;
		gap: 0 12px;
	}
	&__item{
		width: 42px;
	}
}

.win.picxel .mainFont .events_items .eventsItems_sub .eventsItemsSub_item .eventsItemsSubItem_details .prohibited{
	margin-top: 8px;
}





.picxel .events_links{
	width: calc(100% - 56px);
}
.picxel .events_items{
	margin: 0 28px;
}




/*# sourceMappingURL=events.css.map */
</style>
