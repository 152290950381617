<template>
	<div class="check">
		<div class="sendContent">
			<section class="checkSec">
				<h3 class="checkSec_ttl">予約内容の変更・<br class="sp">キャンセル</h3>
				<section class="customerInfo">
					<h4 class="customerInfo_ttl">お客様情報</h4>
					<ul class="inputLists">
						<li class="inputList" v-if="selectEvent">
							<p class="inputList_ttl">ご予約日時</p>
							<div class="inputDate inputList_cont">
								<p class="inputDate_date">{{ selectYear }}年{{ selectMonth }}月{{ selectDate }}日({{ selectDay }})</p>
								<p class="inputDate_time">{{ retakeDate(selectedTime) }}</p>
							</div>
							<p class="inputList_cont" v-html="selectEvent.rsv_course_name"></p>
						</li>
						<li class="inputList" v-if="paidInfo == true">
							<p class="inputList_ttl">料金</p>
							<p class="inputList_cont">{{ price }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">ご予約人数</p>
							<div class="peopleBox">
								<div class="peopleMore">
									<p class="peopleMore_ttl" v-if="non_alcohol_flg == 0">20歳以上飲酒を希望</p>
									<p class="peopleMore_ttl" v-else>20歳以上</p>
									<p class="peopleMore_people">{{ alcohol_num }}人</p>
									<!-- <p class="peopleMore_people">女性{{ women }}人</p> -->
								</div>
								<div class="peopleMore" v-show="non_alcohol_flg == 0">
									<p class="peopleMore_ttl">20歳以上飲酒を希望しない</p>
									<p class="peopleMore_people">{{ non_alcohol_num }}人</p>
									<!-- <p class="peopleMore_people">女性{{ women }}人</p> -->
								</div>
								<div class="peopleBelow" v-show="minor_flg == true">
									<p class="peopleBelow_ttl">20歳未満</p>
									<p class="peopleBelow_less">{{ minor_num }}人</p>
								</div>
							</div>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">代表者名</p>
							<p class="inputList_cont">{{ group_name }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">代表者名（ふりがな）</p>
							<p class="inputList_cont">{{ group_name_kana }}</p>
						</li>
						<li class="inputList" v-if="paidInfo == true && total_num >= 2 && mygflg == false">
							<p class="inputList_ttl">同伴者様</p>
							<p class="inputList_cont">{{ companion_name }}</p>
						</li>
						<li class="inputList" v-if="paidInfo == true && total_num >= 2 && mygflg == false">
							<p class="inputList_ttl">同伴者様（ふりがな）</p>
							<p class="inputList_cont">{{ companion_name_kana }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">ご住所</p>
							<p class="inputList_cont">{{ prefName }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">お電話番号</p>
							<p class="inputList_cont">{{ tel }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">メールアドレス</p>
							<p class="inputList_cont">{{ email }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">ご来場回数</p>
							<p class="inputList_cont">{{ countNum }}</p>
						</li>
						<li class="inputList">
							<p class="inputList_ttl">ご来場手段</p>
							<p class="inputList_cont">{{ visitWay }}</p>
						</li>
						<li class="inputList" v-if="unit != null && unit != 'null'">
							<p class="inputList_ttl">ご来場台数</p>
							<p class="inputList_cont">{{ unit }}台</p>
						</li>
						<li class="inputList" v-if="selectCourseInfo[0].chk_allergy_flg == 1">
							<p class="inputList_ttl">アレルギー有無確認</p>
							<p class="inputList_cont" v-show="allergy == 0">アレルギー無し</p>
							<p class="inputList_cont" v-show="allergy == 1">アレルギー有り</p>
						</li>
						<!-- <li class="inputList" v-if="mygflg == false && note != null && note != 'null'">
							<p class="inputList_ttl">備考</p>
							<pre class="inputList_cont inputList_note">{{ note }}</pre>
						</li> -->
					</ul>
					<div class="btns">
						<button type="button" class="moveBtn changeBtn" @click="changeCont()">入力内容を変更する</button>
						<button type="button" class="moveBtn cancelBtn" @click="openModal()">キャンセルをする</button>
					</div>
				</section>
			</section>
		</div>
		<router-link tag="a" to="/miyagikyo/reservation" class="goToLink" v-show="mygflg == true">トップページへ戻る</router-link>
		<router-link tag="a" to="/yoichi/reservation" class="goToLink" v-show="mygflg == false">トップページへ戻る</router-link>
		<Tel />

		<div class="modal">
			<img src="../assets/img/careful.png" alt="注意" class="modal_img">
			<p class="modal_ttl">キャンセルして<br class="sp">よろしいですか？</p>
			<p class="modal_txt">キャンセルをされますとご予約<br class="sp">いただいた内容が無効となります。</p>
			<div class="carefuls">
				<button type="button" class="carefuls_cancel" @click="reserveCancel()">キャンセルする</button>
				<button type="button" class="carefuls_close" @click="closeModal()">閉じる</button>
			</div>
		</div>
		<div class="modalShadow" @click="closeModal()"></div>
	</div>
</template>

<script>
import Tel from './Tel'
import axios from 'axios'

export default {
	name: 'RservationTop',
	components: {
		Tel
	},
	data () {
		return {
			old_tel: localStorage.getItem('old_tel'),
			res_id: localStorage.getItem('resId'),
			password: localStorage.getItem('password'),
			plant_id: localStorage.getItem('plantId'),
			plantName: null,
			course_id: localStorage.getItem('course_id'),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo')),
			selectCourseInfo: JSON.parse(localStorage.getItem('selectCourseInfo')),
			prefData: JSON.parse(localStorage.getItem('prefData')),
			visitNum: JSON.parse(localStorage.getItem('visitNum')),
			comeWay: JSON.parse(localStorage.getItem('comeWay')),
			selectedDate: localStorage.getItem('selectedDate'),
			selectedTime: localStorage.getItem('selectedTime'),
			selectEvent: JSON.parse(localStorage.getItem('selectedEvent')),
			price: 0,
			selectYear: null,
			selectMonth: null,
			selectDate: null,
			selectDay: null,
			dayOfWeekStr: [ "日", "月", "火", "水", "木", "金", "土" ],
			paidInfo: false,
			movePlan: false,
			mygflg: false,
			// 新規取得データ
			targetDate: null,
			minor_flg: false,
			// 入力項目
			// men: localStorage.getItem("men"),
			// women: localStorage.getItem("women"),
			adult_num: localStorage.getItem("adult_num"),
			minor_num: this.minor_num = localStorage.getItem("minor_num"),
			alcohol_num: localStorage.getItem("alcohol_num"),
			non_alcohol_num: localStorage.getItem("non_alcohol_num"),
			non_alcohol_flg: localStorage.getItem("non_alcohol_flg"),
			total_num: localStorage.getItem("total_num"),
			group_name: localStorage.getItem("group_name"),
			group_name_kana: localStorage.getItem("group_name_kana"),
			companion_name: null,
			companion_name_kana: null,
			pref: localStorage.getItem("pref"),
			prefName: null,
			tel: localStorage.getItem("tel"),
			email: localStorage.getItem("email"),
			count: localStorage.getItem("count"),
			countNum: null,
			visit: localStorage.getItem("visit"),
			visitWay: null,
			unit: localStorage.getItem("unit"),
			allergy: localStorage.getItem("allergy"),
			// note: localStorage.getItem("note"),
			url: "/api/reserve/delete",
			scrollPos: '',
			errors: null,
			winter: null
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: '予約内容の変更・キャンセル'
			}
		},
		// link: [
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    // ],
    // script: [
    //   { type: 'text/javascript', src: '/include/include.js' }
    // ]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		// 20歳未満が参加できるか否か
		if(this.selectEvent.condition_minor_disallow_flg == 2) {
			this.minor_flg = true;
		}else {
			this.minor_flg = false;
		}

		// 金額
		this.price = this.selectEvent.rsv_course_fee;
		// this.price = this.price.replace(/,/g, '');
		// this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

		// 住所のnameを保存していたidを元に探す
		for(var k = 0; k < this.prefData.length; k++) {
			if(this.prefData[k].departure_id == this.pref) {
				this.prefName = this.prefData[k].departure_name;
			}
		}

		// this.visitの値を元にご来場手段のnameを探す
		for(var m = 0; m < this.comeWay.length; m++) {
			if(this.comeWay[m].come_way_id == this.visit) {
				this.visitWay = this.comeWay[m].come_way_name;
			}
		}

		// this.coutの値を元にご来場回数のnameを探す
		for(var l = 0; l < this.visitNum.length; l++) {
			if(this.visitNum[l].vst_come_num_id == this.count) {
				this.countNum = this.visitNum[l].vst_come_num_name;
			}
		}

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(this.mygflg == true) {
				this.$router.push({ path: '/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = '宮城峡蒸溜所';
		}else {
			this.plantName = '余市蒸溜所';
		}
		this.$emit('updateHead');

		this.selectYear = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCFullYear();
		this.selectMonth = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCMonth() + 1;
		this.selectDate = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getUTCDate();
		this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDay()];
	},
	methods: {
		retakeDate(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		changeCont() {
			if(this.plant_id == 1000) {
				this.$router.push({ path: '/miyagikyo/reservation/form' });
			}else {
				this.$router.push({ path: '/yoichi/reservation/form' });
			}
		},

		openModal() {
			this.scrollPos = window.$(window).scrollTop();
			window.$("body").addClass("fixed").css({
				top: -this.scrollPos
			});
			window.$(".modal, .modalShadow").show();
		},

		closeModal() {
			window.$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			window.$(".modal, .modalShadow").hide();
		},

		reserveCancel() {
			axios({
				method: "post",
				url: this.url,
				timeout: 5000000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					reservation_id: this.res_id,
					telno: this.old_tel,
					password: this.password
				}
			}).then(() => {
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref_id');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('alcohol_num');
				localStorage.removeItem('non_alcohol_num');
				localStorage.removeItem('non_alcohol_flg');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				// bodyの固定を解除する
				window.$("body").removeClass("fixed").css({
					top: 0
				});
				// 結果ページに飛ぶ
				if(location.pathname.indexOf('miyagikyo') != -1) {
					this.$router.push({ path: '/miyagikyo/reservation/cancel' });
				}else {
					this.$router.push({ path: '/yoichi/reservation/cancel' });
				}
			}).catch(err => {
				// bodyの固定を解除する
				window.$("body").removeClass("fixed").css({
					top: 0
				});
				this.errors = err.response.data.data;
				localStorage.setItem('errs', JSON.stringify(this.errors));
				// 失敗ページに飛ぶ
				this.$router.push({ path: '/reservation/fatal_error' });
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.sendContent {
		width: 800px;
		margin: 0 auto;
		padding: 50px 0 60px;
		border-top: 1px solid #E8E8E8;
	}

	// 768以下
	.picxel .sendContent {
		width: calc(100% - 50px);
		padding: 40px 0;
		border-top: 1px solid #E8E8E8;
	}

	.checkSec {
		margin-top: 90px;
		&_ttl {
			margin: 0 auto;
			font-size: 32px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 100;
		}
	}

	// 768以下
	.picxel .checkSec {
		margin-top: 0;
		&_ttl {
			font-size: 22px;
		}
	}

	.customerInfo {
		&_ttl {
			font-size: 24px;
			margin: 52px 0 55px;
		}
	}

	// 768以下
	.picxel .customerInfo {
		&_ttl {
			font-size: 16px;
			margin: 40px 0 28px;
		}
	}

	// 入力確認
	.inputLists {
		background-color: #fafafa;
		width: 100%;
		padding: 60px 95px 30px;
		list-style: none;
		box-sizing: border-box;
	}

	// 768以下
	.picxel .inputLists {
		padding: 50px 15px 18px;
	}

	.inputList {
		margin-bottom: 30px;
		&_ttl {
			font-size: 16px;
			color: #915747;
			margin-bottom: 8px;
		}
		&_cont {
			font-size: 20px;
			font-weight: bold;
			line-height: 1.8 !important;
		}
		// &_note {
		// 	white-space: pre-wrap;
		// }
	}

	// 768以下
	.picxel .inputList {
		margin-bottom: 32px;
		&_ttl {
			font-size: 14px;
			margin-bottom: 8px;
		}
		&_cont {
			font-size: 16px;
		}
	}

	// コース
	.inputDate {
		display: flex;
		&_date {
			margin-right: 10px;
		}
	}

	// 768以下
	.picxel .inputDate {
		&_date {
			margin-right: 10px;
		}
	}

	// ご予約人数
	.peopleBox {
		// display: flex;
		// align-items: center;
		// flex-wrap: wrap;
		// gap: 8px 20px;
		justify-content: flex-start;
	}
	.peopleBox > div{
		&:nth-child(n+2){
			margin-top: 10px;
		}
	}
	// 768以下
	.picxel .peopleBox {
		display: block;
		font-size: 16px;
	}

	.peopleMore {
		display: flex;
		align-items: center;
		&_ttl {
			font-size: 16px;
		}
		&_people {
			font-size: 20px;
			font-weight: bold;
			margin-left: 22px;
		}
	}

	// 768以下
	.picxel .peopleMore {
		display: block;
		&_ttl {
			margin-bottom: 5px;
		}
		&_people {
			display: inline-block;
			margin-left: 0;
			margin-right: 16px;
		}
	}

	.peopleBelow {
		display: flex;
		align-items: center;
		&_ttl {
			font-size: 16px;
		}
		&_less {
			font-size: 20px;
			font-weight: bold;
			margin-left: 22px;
		}
	}

	// 768以下
	.picxel .peopleBelow {
		display: block;
		margin-left: 0;
		margin-top: 15px;
		&_ttl {
			margin-bottom: 5px;
		}
		&_less {
			margin-left: 0;
		}
	}

	// ボタン
	.btns {
		width: calc(100% - 140px);
		margin: 80px auto 20px;
		display: flex;
		justify-content: space-between;
		.moveBtn {
			font-size: 18px;
			line-height: 1 !important;
			outline: none;
			background: #fff;
			cursor: pointer;
			border: 1px solid #333333;
			width: calc(50% - 10px);
			padding: 18px 0;
			color: #333333;
		}
		.changeBtn {
			text-decoration: none;
			text-align: center;
		}
	}

	// 768以下
	.picxel .btns {
		width: 100%;
		display: block;
		margin-top: 60px;
		.moveBtn {
			width: 100%;
			font-size: 15px;
			padding: 18px 0;
			margin-bottom: 20px;
		}
	}

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 18px;
		width: 320px;
		text-align: center;
		line-height: 1 !important;
		margin: 0 auto 79px;
		background-color: #fff;
		color: #000;
		border: 1px solid #000;
		padding: 21px 0;
		cursor: pointer;
	}

	// 768以下
	.picxel .goToLink {
		font-size: 18px;
		width: calc(100% - 50px);
		margin-bottom: 40px;
		padding: 21px 0;
	}

	.modal {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		background-color: #fff;
		width: 600px;
		padding: 39px 65px 60px;
		box-sizing: border-box;
		&_img {
			display: block;
			width: 73px;
			margin: 0 auto 13px;
		}
		&_ttl {
			font-size: 26px;
			text-align: center;
			margin-bottom: 6px;
		}
		&_txt {
			font-size: 16px;
			text-align: center;
		}
	}

	// 768以下
	.picxel .modal {
		width: calc(100% - 50px);
		padding: 33px 37px 37px;
		&_img {
			width: 44px;
			margin-bottom: 13px;
		}
		&_ttl {
			font-size: 20px;
		}
		&_txt {
			font-size: 12px;
		}
	}

	.carefuls {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		margin: 26px auto 0;
		&_cancel {
			width: calc(50% - 10px);
			font-size: 18px;
			color: #fff;
			outline: none;
			background-color: #ba0000;
			padding: 14px 0;
			border: 1px solid #ba0000;
			cursor: pointer;
		}
		&_close {
			width: calc(50% - 10px);
			font-size: 18px;
			line-height: 1;
			color: #adadad;
			outline: none;
			background: none;
			padding: 14px 0;
			border: 1px solid #adadad;
			cursor: pointer;
		}
	}

	// 768以下
	.picxel .carefuls {
		width: 160px;
		display: block;
		margin-top: 26px;
		&_cancel {
			width: 100%;
			font-size: 15px;
			margin-bottom: 10px;
			padding: 14px 0;
			border: 1px solid #ba0000;
		}
		&_close {
			width: 100%;
			font-size: 15px;
			padding: 14px 0;
			border: 1px solid #adadad;
		}
	}

	.modalShadow {
		display: none;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: rgba($color: #000000, $alpha: 0.8);
		cursor: pointer;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
