<template>
	<div class="mainVisual">
		<div class="visualCnter">
			<template v-if="eventflg">
				<h2 class="visualCnter_ttl mincho">イベント・セミナー情報</h2>
			</template>
			<template v-else>
				<h2 class="visualCnter_ttl mincho" v-show="mygflg == true">宮城峡蒸溜所見学のお申込み</h2>
				<h2 class="visualCnter_ttl mincho" v-show="mygflg == false">余市蒸溜所見学のお申込み</h2>
			</template>
			<!-- <p class="visualCnter_box">完全予約制</p> -->
		</div>
		<router-link tag="a" to="/miyagikyo/reservation/reserve" class="mainVisual_link" v-show="mygflg == true && eventflg == false">ご予約の変更・キャンセルはこちら</router-link>
		<router-link tag="a" to="/yoichi/reservation/reserve" class="mainVisual_link" v-show="mygflg == false && eventflg == false">ご予約の変更・キャンセルはこちら</router-link>
	</div>
</template>

<script>
export default {
	name: 'MainVisual',
	data () {
		return {
			mygflg: false,
			eventflg: false
		}
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
			if(location.pathname.indexOf('event') != -1) {
				this.eventflg = true;
				window.$('.mainVisual').addClass('event');
			}else {
				window.$('.mainVisual').addClass('miyagi');
			}
		}else {
			if(location.pathname.indexOf('event') != -1) {
				this.eventflg = true;
				window.$('.mainVisual').addClass('event');
			}else {
				window.$('.mainVisual').addClass('yoichi');
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	.mainVisual {
		position: relative;
		width: 100%;
		height: 180px;
		&.miyagi {
			background-image: url(../assets/img/miyagiVisual.jpg);
			background-size: cover;
			background-position: center;
		}
		&.yoichi {
			background-image: url(../assets/img/yoichiVisual.jpg);
			background-size: cover;
			background-position: center;
		}
		&.event {
			background-image: url(../assets/img/event/img_top.jpg);
			background-size: cover;
			background-position: center;
		}
		&_link {
			position: absolute;
			width: 100%;
			text-align: center;
			left: 50%;
			bottom: 20px;
			transform: translateX(-50%);
			color: #fff;
			font-size: 14px;
			letter-spacing: 0.05em;
			text-decoration: underline;
			&::after {
				position: absolute;
				content: ">";
				top: 50%;
				transform: translateY(-50%);
				padding-left: 10px;
			}
		}
	}

	// 768以下
	.picxel .mainVisual {
		height: 130px;
		&_link {
			font-size: 12px;
			bottom: 16px;
		}
	}

	.visualCnter {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		&_ttl {
			font-size: 40px;
			font-weight: 100;
			letter-spacing: 0.12em;
			// margin-bottom: 20px;
			// font-family: 'HiraMinProN-W6', 'Noto Serif JP', serif !important;
		}
		// &_box {
		// 	background-color: #af9754;
		// 	width: 141px;
		// 	line-height: 1;
		// 	font-size: 16px;
		// 	padding: 5px 0 8px;
		// 	margin: 5px auto 0;
		// 	@media screen and (max-width: 768px) {
		// 		width: 282px;
		// 		padding: 10px 0 16px;
		// 		font-size: 26px;
		// 	}
		// }
	}

	// 768以下
	.picxel .visualCnter {
		&_ttl {
			font-size: 24px;
			// margin-bottom: 20px;
		}
	}
</style>
